export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BigDecimal: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Long: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  formFields?: Maybe<Array<AddressFormField>>;
  lastName?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  stateOrProvince?: Maybe<Scalars['String']['output']>;
};

export type AddressFormField = {
  __typename?: 'AddressFormField';
  name?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Aggregated = {
  __typename?: 'Aggregated';
  availableToSell: Scalars['Long']['output'];
  warningLevel: Scalars['Int']['output'];
};

export type AggregatedInventory = {
  __typename?: 'AggregatedInventory';
  availableToSell: Scalars['Int']['output'];
  warningLevel: Scalars['Int']['output'];
};

export type Banner = Node & {
  __typename?: 'Banner';
  campaignFilter?: Maybe<BannerCampaignFilter>;
  couponCode?: Maybe<Scalars['String']['output']>;
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  fromDate?: Maybe<Scalars['DateTime']['output']>;
  hide?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  location?: Maybe<BannerLocation>;
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  products?: Maybe<ProductConnection>;
  showOnStartpage?: Maybe<Scalars['Boolean']['output']>;
  slug: Scalars['String']['output'];
  toDate?: Maybe<Scalars['DateTime']['output']>;
};


export type BannerProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BannerCampaignFilter = {
  __typename?: 'BannerCampaignFilter';
  attributeName: Scalars['String']['output'];
  attributeValue: Scalars['String']['output'];
  searchFilters?: Maybe<Scalars['String']['output']>;
};

export type BannerConnection = {
  __typename?: 'BannerConnection';
  edges?: Maybe<Array<Maybe<BannerEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type BannerEdge = {
  __typename?: 'BannerEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Banner;
};

export enum BannerLocation {
  Bottom = 'BOTTOM',
  Top = 'TOP'
}

export type Brand = Node & {
  __typename?: 'Brand';
  defaultImage?: Maybe<Image>;
  description?: Maybe<Scalars['String']['output']>;
  entityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  metaDesc: Scalars['String']['output'];
  metaKeywords: Array<Scalars['String']['output']>;
  metafields: MetafieldConnection;
  name: Scalars['String']['output'];
  pageTitle: Scalars['String']['output'];
  path: Scalars['String']['output'];
  products: ProductConnection;
  searchKeywords: Array<Scalars['String']['output']>;
  slug: Scalars['String']['output'];
};


export type BrandMetafieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  namespace: Scalars['String']['input'];
};


export type BrandProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BrandConnection = {
  __typename?: 'BrandConnection';
  edges?: Maybe<Array<Maybe<BrandEdge>>>;
  pageInfo: PageInfo;
};

export type BrandEdge = {
  __typename?: 'BrandEdge';
  cursor: Scalars['String']['output'];
  node: Brand;
};

export type Breadcrumb = {
  __typename?: 'Breadcrumb';
  entityId: Scalars['Int']['output'];
  longName?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  path?: Maybe<Scalars['String']['output']>;
};

export type BreadcrumbConnection = {
  __typename?: 'BreadcrumbConnection';
  edges?: Maybe<Array<Maybe<BreadcrumbEdge>>>;
  pageInfo: PageInfo;
};

export type BreadcrumbEdge = {
  __typename?: 'BreadcrumbEdge';
  cursor: Scalars['String']['output'];
  node: Breadcrumb;
};

export type BulkPricingFixedPriceDiscount = BulkPricingTier & {
  __typename?: 'BulkPricingFixedPriceDiscount';
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
  minimumQuantity: Scalars['Int']['output'];
  price: Scalars['BigDecimal']['output'];
};

export type BulkPricingPercentageDiscount = BulkPricingTier & {
  __typename?: 'BulkPricingPercentageDiscount';
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
  minimumQuantity: Scalars['Int']['output'];
  percentOff: Scalars['BigDecimal']['output'];
};

export type BulkPricingRelativePriceDiscount = BulkPricingTier & {
  __typename?: 'BulkPricingRelativePriceDiscount';
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
  minimumQuantity: Scalars['Int']['output'];
  priceAdjustment: Scalars['BigDecimal']['output'];
};

export type BulkPricingTier = {
  maximumQuantity?: Maybe<Scalars['Int']['output']>;
  minimumQuantity: Scalars['Int']['output'];
};

export type Bundle = {
  __typename?: 'Bundle';
  items?: Maybe<Array<Maybe<BundleItem>>>;
  totalSavings: Money;
  unbundledPrice: Money;
};

export type BundleItem = {
  __typename?: 'BundleItem';
  defaultImageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  options: Array<Maybe<SelectedVariantOption>>;
  path: Scalars['String']['output'];
  price: Money;
  productId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  variantId: Scalars['Int']['output'];
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Campaign = {
  __typename?: 'Campaign';
  amount: Scalars['BigDecimal']['output'];
  id: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type Cart = Node & {
  __typename?: 'Cart';
  amount?: Maybe<Money>;
  baseAmount?: Maybe<Money>;
  campaignDiscountAmount?: Maybe<Money>;
  containsOtcItem?: Maybe<Scalars['Boolean']['output']>;
  containsPrescriptionItem?: Maybe<Scalars['Boolean']['output']>;
  containsVetFood?: Maybe<Scalars['Boolean']['output']>;
  frozenItemsHandlingFee?: Maybe<Money>;
  id: Scalars['ID']['output'];
  itemCount?: Maybe<Scalars['Int']['output']>;
  items: Array<CartItem>;
  metaFields?: Maybe<Array<Maybe<CartMetaField>>>;
  promo?: Maybe<Promo>;
  promoDiscountAmount?: Maybe<Money>;
  storageClimates: Array<Maybe<ProductStorageClimate>>;
};

export type CartItem = Node & {
  __typename?: 'CartItem';
  animalType?: Maybe<Scalars['String']['output']>;
  brand?: Maybe<Brand>;
  campaign?: Maybe<Campaign>;
  categories: Array<Category>;
  defaultImageUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  options?: Maybe<Array<Maybe<SelectedVariantOption>>>;
  path?: Maybe<Scalars['String']['output']>;
  prices: VariantPrices;
  primaryBreadcrumb?: Maybe<Scalars['String']['output']>;
  productId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  storageClimate: ProductStorageClimate;
  type?: Maybe<ProductType>;
  variantId: Scalars['Int']['output'];
};


export type CartItemPricesArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CartItemInput = {
  productId?: InputMaybe<Scalars['Int']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  variantId?: InputMaybe<Scalars['Int']['input']>;
};

export type CartMetaField = {
  __typename?: 'CartMetaField';
  entityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  namespace: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CatalogProductOption = {
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type CatalogProductOptionValue = {
  entityId: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type Category = Node & {
  __typename?: 'Category';
  animal?: Maybe<Scalars['String']['output']>;
  breadcrumbs: BreadcrumbConnection;
  defaultImage?: Maybe<Image>;
  description: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  metaDesc: Scalars['String']['output'];
  metafields: MetafieldConnection;
  name: Scalars['String']['output'];
  pageTitle: Scalars['String']['output'];
  path: Scalars['String']['output'];
  products: ProductConnection;
};


export type CategoryBreadcrumbsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  depth: Scalars['Int']['input'];
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type CategoryMetafieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  namespace: Scalars['String']['input'];
};


export type CategoryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  pageInfo: PageInfo;
};

export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  cursor: Scalars['String']['output'];
  node: Category;
};

export type CategoryTreeItem = {
  __typename?: 'CategoryTreeItem';
  children: Array<CategoryTreeItem>;
  entityId: Scalars['Int']['output'];
  menu?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
};

export type CheckboxOption = CatalogProductOption & {
  __typename?: 'CheckboxOption';
  checkedByDefault: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type ContactField = {
  __typename?: 'ContactField';
  address: Scalars['String']['output'];
  addressType: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email: Scalars['String']['output'];
  phone: Scalars['String']['output'];
};

export type CustomField = {
  __typename?: 'CustomField';
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type CustomFieldConnection = {
  __typename?: 'CustomFieldConnection';
  edges?: Maybe<Array<Maybe<CustomFieldEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldEdge = {
  __typename?: 'CustomFieldEdge';
  cursor: Scalars['String']['output'];
  node: CustomField;
};

export type Customer = {
  __typename?: 'Customer';
  addressCount: Scalars['Int']['output'];
  attributeCount: Scalars['Int']['output'];
  attributes: CustomerAttributes;
  company: Scalars['String']['output'];
  customerGroupId: Scalars['Int']['output'];
  email: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  phone: Scalars['String']['output'];
  storeCredit: Array<Money>;
  taxExemptCategory: Scalars['String']['output'];
};

export type CustomerAttribute = {
  __typename?: 'CustomerAttribute';
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type CustomerAttributes = {
  __typename?: 'CustomerAttributes';
  attribute: CustomerAttribute;
};


export type CustomerAttributesAttributeArgs = {
  entityId: Scalars['Int']['input'];
};

export type DateFieldOption = CatalogProductOption & {
  __typename?: 'DateFieldOption';
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type DateTimeExtended = {
  __typename?: 'DateTimeExtended';
  utc: Scalars['DateTime']['output'];
};

export type DisplayField = {
  __typename?: 'DisplayField';
  extendedDateFormat: Scalars['String']['output'];
  shortDateFormat: Scalars['String']['output'];
};

export type DistanceFilter = {
  latitude: Scalars['Float']['input'];
  lengthUnit: LengthUnit;
  longitude: Scalars['Float']['input'];
  radius: Scalars['Float']['input'];
};

export type FileUploadFieldOption = CatalogProductOption & {
  __typename?: 'FileUploadFieldOption';
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type GetOrdersParams = {
  cartId?: InputMaybe<Scalars['String']['input']>;
  channelId?: InputMaybe<Scalars['Int']['input']>;
  customerId?: InputMaybe<Scalars['Int']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isPending?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  maxDateCreated?: InputMaybe<Scalars['String']['input']>;
  maxDateModified?: InputMaybe<Scalars['String']['input']>;
  maxId?: InputMaybe<Scalars['Int']['input']>;
  maxTotal?: InputMaybe<Scalars['Int']['input']>;
  minDateCreated?: InputMaybe<Scalars['String']['input']>;
  minDateModified?: InputMaybe<Scalars['String']['input']>;
  minId?: InputMaybe<Scalars['Int']['input']>;
  minTotal?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
  statusId?: InputMaybe<Scalars['Int']['input']>;
};

export type Image = {
  __typename?: 'Image';
  altText: Scalars['String']['output'];
  isDefault: Scalars['Boolean']['output'];
  url: Scalars['String']['output'];
  urlOriginal: Scalars['String']['output'];
};


export type ImageUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width: Scalars['Int']['input'];
};

export type ImageConnection = {
  __typename?: 'ImageConnection';
  edges?: Maybe<Array<Maybe<ImageEdge>>>;
  pageInfo: PageInfo;
};

export type ImageEdge = {
  __typename?: 'ImageEdge';
  cursor: Scalars['String']['output'];
  node: Image;
};

export type Inventory = {
  __typename?: 'Inventory';
  locations: LocationConnection;
};


export type InventoryLocationsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  codes?: InputMaybe<Array<Scalars['String']['input']>>;
  distanceFilter?: InputMaybe<DistanceFilter>;
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  typeIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InventoryByLocations = {
  __typename?: 'InventoryByLocations';
  availableToSell: Scalars['Long']['output'];
  isInStock: Scalars['Boolean']['output'];
  locationEntityId: Scalars['Long']['output'];
  warningLevel: Scalars['Int']['output'];
};

export enum LengthUnit {
  Kilometres = 'Kilometres',
  Miles = 'Miles'
}

export type LocationConnection = {
  __typename?: 'LocationConnection';
  edges?: Maybe<Array<Maybe<LocationEdge>>>;
  pageInfo: PageInfo;
};

export type LocationEdge = {
  __typename?: 'LocationEdge';
  cursor: Scalars['String']['output'];
  node: InventoryByLocations;
};

export type LoginResult = {
  __typename?: 'LoginResult';
  result: Scalars['String']['output'];
};

export type LogoField = {
  __typename?: 'LogoField';
  image: Image;
  title: Scalars['String']['output'];
};

export type LogoutResult = {
  __typename?: 'LogoutResult';
  result: Scalars['String']['output'];
};

export type Measurement = {
  __typename?: 'Measurement';
  unit: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type MetafieldConnection = {
  __typename?: 'MetafieldConnection';
  edges?: Maybe<Array<Maybe<MetafieldEdge>>>;
  pageInfo: PageInfo;
};

export type MetafieldEdge = {
  __typename?: 'MetafieldEdge';
  cursor: Scalars['String']['output'];
  node: Metafields;
};

export type Metafields = {
  __typename?: 'Metafields';
  entityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type Money = {
  __typename?: 'Money';
  currencyCode: Scalars['String']['output'];
  value: Scalars['BigDecimal']['output'];
};

export type MoneyRange = {
  __typename?: 'MoneyRange';
  max: Money;
  min: Money;
};

export type MultiLineTextFieldOption = CatalogProductOption & {
  __typename?: 'MultiLineTextFieldOption';
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type MultipleChoiceOption = CatalogProductOption & {
  __typename?: 'MultipleChoiceOption';
  displayName: Scalars['String']['output'];
  displayStyle: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
  values: ProductOptionValueConnection;
};


export type MultipleChoiceOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type MultipleChoiceOptionValue = CatalogProductOptionValue & {
  __typename?: 'MultipleChoiceOptionValue';
  entityId: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCartItems?: Maybe<Cart>;
  cancelSubscription?: Maybe<Scalars['Boolean']['output']>;
  createCart?: Maybe<Cart>;
  createEcom2Basket?: Maybe<Cart>;
  deleteCart?: Maybe<Scalars['Boolean']['output']>;
  deleteCartItem?: Maybe<Cart>;
  deleteEcom2Basket?: Maybe<Cart>;
  login: LoginResult;
  logout: LogoutResult;
  pauseSubscription?: Maybe<Subscription>;
  resumeSubscription?: Maybe<ResumeSubscriptionResult>;
  updateCartItem?: Maybe<Cart>;
};


export type MutationAddCartItemsArgs = {
  cartItems?: InputMaybe<Array<CartItemInput>>;
  id: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCancelSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateCartArgs = {
  cartItems?: InputMaybe<Array<CartItemInput>>;
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateEcom2BasketArgs = {
  basketId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteCartArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteCartItemArgs = {
  cartItemId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteEcom2BasketArgs = {
  id: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLoginArgs = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationPauseSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResumeSubscriptionArgs = {
  id: Scalars['ID']['input'];
};


export type MutationUpdateCartItemArgs = {
  cartItem: CartItemInput;
  cartItemId: Scalars['ID']['input'];
  id: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type NumberFieldOption = CatalogProductOption & {
  __typename?: 'NumberFieldOption';
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type OptionConnection = {
  __typename?: 'OptionConnection';
  edges?: Maybe<Array<Maybe<OptionEdge>>>;
  pageInfo: PageInfo;
};

export type OptionEdge = {
  __typename?: 'OptionEdge';
  cursor: Scalars['String']['output'];
  node: ProductOption;
};

export type OptionValueConnection = {
  __typename?: 'OptionValueConnection';
  edges?: Maybe<Array<Maybe<OptionValueEdge>>>;
  pageInfo: PageInfo;
};

export type OptionValueEdge = {
  __typename?: 'OptionValueEdge';
  cursor: Scalars['String']['output'];
  node: ProductOptionValue;
};

export type OptionValueId = {
  optionEntityId: Scalars['Int']['input'];
  valueEntityId: Scalars['Int']['input'];
};

export type Order = Node & {
  __typename?: 'Order';
  billingAddress: Address;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  discountAmount?: Maybe<Money>;
  entityId: Scalars['Int']['output'];
  handlingCost?: Maybe<Money>;
  id: Scalars['ID']['output'];
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  orderLines?: Maybe<Array<OrderLine>>;
  returnShippingService: Scalars['String']['output'];
  shippingAddress?: Maybe<Address>;
  shippingCost?: Maybe<Money>;
  shippingService?: Maybe<Scalars['String']['output']>;
  status?: Maybe<OrderStatus>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  subtotal?: Maybe<Money>;
  total?: Maybe<Money>;
};

export type OrderConnection = {
  __typename?: 'OrderConnection';
  edges?: Maybe<Array<Maybe<OrderEdge>>>;
  pageInfo: PageInfo;
};

export type OrderEdge = {
  __typename?: 'OrderEdge';
  cursor: Scalars['String']['output'];
  node: Order;
};

export type OrderLine = Node & {
  __typename?: 'OrderLine';
  baseTotal?: Maybe<Money>;
  brand?: Maybe<SimpleBrand>;
  categoryIds?: Maybe<Array<Scalars['Int']['output']>>;
  currentProductStatus?: Maybe<OrderLineCurrentProductStatus>;
  entityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isPrescriptionBag: Scalars['Boolean']['output'];
  isRefundable?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  options: Array<SelectedVariantOption>;
  prices: OrderLinePrices;
  productId: Scalars['Int']['output'];
  productUrl?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  quantityNotRefunded: Scalars['Int']['output'];
  quantityRefunded: Scalars['Int']['output'];
  sku: Scalars['String']['output'];
  slug?: Maybe<Scalars['String']['output']>;
  total: Money;
  type: Scalars['String']['output'];
  variantId: Scalars['Int']['output'];
};

export type OrderLineCurrentProductStatus = {
  __typename?: 'OrderLineCurrentProductStatus';
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isPurchasable?: Maybe<Scalars['Boolean']['output']>;
  missingProduct: Scalars['Boolean']['output'];
  missingVariant: Scalars['Boolean']['output'];
};

export type OrderLinePrices = Prices & {
  __typename?: 'OrderLinePrices';
  basePrice?: Maybe<Money>;
  price: Money;
  salePrice?: Maybe<Money>;
};

export type OrderStatus = {
  __typename?: 'OrderStatus';
  entityId: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export type PageInfo = {
  __typename?: 'PageInfo';
  count?: Maybe<Scalars['Int']['output']>;
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']['output']>;
  perPage?: Maybe<Scalars['Int']['output']>;
  startCursor?: Maybe<Scalars['String']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};

export type PriceRanges = {
  __typename?: 'PriceRanges';
  priceRange: MoneyRange;
  retailPriceRange?: Maybe<MoneyRange>;
};

export type Prices = {
  basePrice?: Maybe<Money>;
  price: Money;
  salePrice?: Maybe<Money>;
};

export type Product = Node & {
  __typename?: 'Product';
  addToCartUrl: Scalars['String']['output'];
  addToWishlistUrl: Scalars['String']['output'];
  animalCategories: Array<Maybe<CategoryEdge>>;
  /** @deprecated Use status inside availabilityV2 instead. */
  availability: Scalars['String']['output'];
  /** @deprecated Use description inside availabilityV2 instead. */
  availabilityDescription: Scalars['String']['output'];
  availabilityV2: ProductAvailability;
  brand?: Maybe<SimpleBrand>;
  campaign?: Maybe<Campaign>;
  categories: CategoryConnection;
  categoryIds?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  /** @deprecated Alpha version. Do not use in production. */
  createdAt: DateTimeExtended;
  customFields: CustomFieldConnection;
  defaultImage?: Maybe<Image>;
  defaultVariant?: Maybe<Variant>;
  depth?: Maybe<Measurement>;
  description: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  excerpt?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Measurement>;
  id: Scalars['ID']['output'];
  images: ImageConnection;
  inventory: ProductInventory;
  isAvailable?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isHidden?: Maybe<Scalars['Boolean']['output']>;
  isSubscribable?: Maybe<Scalars['Boolean']['output']>;
  isVetRecommended?: Maybe<Scalars['Boolean']['output']>;
  mainCategoryId?: Maybe<Scalars['Int']['output']>;
  maxPurchaseQuantity?: Maybe<Scalars['Int']['output']>;
  metaDesc: Scalars['String']['output'];
  metafields: MetafieldConnection;
  minPurchaseQuantity?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  options: OptionConnection;
  pageTitle: Scalars['String']['output'];
  path: Scalars['String']['output'];
  plainTextDescription: Scalars['String']['output'];
  /** @deprecated Use priceRanges inside prices node instead. */
  priceRanges?: Maybe<PriceRanges>;
  prices?: Maybe<ProductPrices>;
  productOptions: ProductOptionConnection;
  relatedProducts?: Maybe<RelatedProductsConnection>;
  reviewSummary: Reviews;
  sku: Scalars['String']['output'];
  slug: Scalars['String']['output'];
  storageClimate: ProductStorageClimate;
  type: ProductType;
  variants: VariantConnection;
  videos?: Maybe<Array<Maybe<Video>>>;
  warranty: Scalars['String']['output'];
  weight?: Maybe<Measurement>;
  width?: Maybe<Measurement>;
};


export type ProductCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCustomFieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  names?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type ProductImagesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductMetafieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  namespace: Scalars['String']['input'];
};


export type ProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductPlainTextDescriptionArgs = {
  characterLimit?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductPriceRangesArgs = {
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductPricesArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
};


export type ProductProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductRelatedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductVariantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  optionValueIds?: InputMaybe<Array<OptionValueId>>;
};

export type ProductAvailability = {
  description: Scalars['String']['output'];
  status: ProductAvailabilityStatus;
};

export enum ProductAvailabilityStatus {
  Available = 'Available',
  Preorder = 'Preorder',
  Unavailable = 'Unavailable'
}

export type ProductAvailable = ProductAvailability & {
  __typename?: 'ProductAvailable';
  description: Scalars['String']['output'];
  status: ProductAvailabilityStatus;
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  pageInfo: PageInfo;
};

export type ProductEdge = {
  __typename?: 'ProductEdge';
  cursor: Scalars['String']['output'];
  node: Product;
};

export type ProductInventory = {
  __typename?: 'ProductInventory';
  aggregated?: Maybe<AggregatedInventory>;
  hasVariantInventory: Scalars['Boolean']['output'];
  isInStock: Scalars['Boolean']['output'];
};

export type ProductOption = {
  __typename?: 'ProductOption';
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
  values: OptionValueConnection;
};


export type ProductOptionValuesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type ProductOptionConnection = {
  __typename?: 'ProductOptionConnection';
  edges?: Maybe<Array<Maybe<ProductOptionEdge>>>;
  pageInfo: PageInfo;
};

export type ProductOptionEdge = {
  __typename?: 'ProductOptionEdge';
  cursor: Scalars['String']['output'];
  node: CatalogProductOption;
};

export type ProductOptionValue = {
  __typename?: 'ProductOptionValue';
  entityId: Scalars['Int']['output'];
  label: Scalars['String']['output'];
};

export type ProductOptionValueConnection = {
  __typename?: 'ProductOptionValueConnection';
  edges?: Maybe<Array<Maybe<ProductOptionValueEdge>>>;
  pageInfo: PageInfo;
};

export type ProductOptionValueEdge = {
  __typename?: 'ProductOptionValueEdge';
  cursor: Scalars['String']['output'];
  node: CatalogProductOptionValue;
};

export type ProductPickListOptionValue = CatalogProductOptionValue & {
  __typename?: 'ProductPickListOptionValue';
  entityId: Scalars['Int']['output'];
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  productId: Scalars['Int']['output'];
};

export type ProductPreOrder = ProductAvailability & {
  __typename?: 'ProductPreOrder';
  description: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  status: ProductAvailabilityStatus;
  willBeReleasedAt?: Maybe<DateTimeExtended>;
};

export type ProductPrices = Prices & {
  __typename?: 'ProductPrices';
  basePrice?: Maybe<Money>;
  isCheapestVariantPrice?: Maybe<Scalars['Boolean']['output']>;
  price: Money;
  pricePerKilo?: Maybe<Money>;
  pricePerLiter?: Maybe<Money>;
  pricePerUnit?: Maybe<Money>;
  promoPrice?: Maybe<Money>;
  salePrice?: Maybe<Money>;
};

export type ProductRecommendation = Node & {
  __typename?: 'ProductRecommendation';
  id: Scalars['ID']['output'];
  journalId: Scalars['Int']['output'];
  productId: Scalars['Int']['output'];
};

export enum ProductStorageClimate {
  Ambient = 'ambient',
  Chilled = 'chilled',
  Frozen = 'frozen',
  RoomTemp = 'roomTemp'
}

export enum ProductType {
  Digital = 'Digital',
  Physical = 'Physical'
}

export type ProductUnavailable = ProductAvailability & {
  __typename?: 'ProductUnavailable';
  description: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
  status: ProductAvailabilityStatus;
};

export type Promo = {
  __typename?: 'Promo';
  amount?: Maybe<Scalars['BigDecimal']['output']>;
  appliesTo?: Maybe<PromoAppliesTo>;
  code?: Maybe<Scalars['String']['output']>;
  enabled?: Maybe<Scalars['Boolean']['output']>;
  excludedBrands?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  isCombinable?: Maybe<Scalars['Boolean']['output']>;
  maxUsesPerCustomer?: Maybe<Scalars['Int']['output']>;
  minPurchase?: Maybe<Scalars['BigDecimal']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PromoAppliesTo = {
  __typename?: 'PromoAppliesTo';
  entity?: Maybe<Scalars['String']['output']>;
  ids?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
};

export type Query = {
  __typename?: 'Query';
  banner?: Maybe<Banner>;
  banners: BannerConnection;
  bestSellingProducts: ProductConnection;
  brands: BrandConnection;
  cart?: Maybe<Cart>;
  categories?: Maybe<CategoryConnection>;
  categoryTree: Array<CategoryTreeItem>;
  customer?: Maybe<Customer>;
  featuredProducts: ProductConnection;
  /** @deprecated Alpha version. Do not use in production. */
  inventory: Inventory;
  node?: Maybe<Node>;
  order?: Maybe<Order>;
  orders?: Maybe<OrderConnection>;
  previouslyOrderedProducts?: Maybe<ProductConnection>;
  productRecommendations?: Maybe<ProductConnection>;
  products: ProductConnection;
  route: Route;
  settings?: Maybe<Settings>;
  subscription?: Maybe<Subscription>;
  subscriptions: SubscriptionConnection;
};


export type QueryBannerArgs = {
  id: Scalars['ID']['input'];
};


export type QueryBestSellingProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryBrandsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  productEntityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};


export type QueryCartArgs = {
  id: Scalars['ID']['input'];
  promoCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFeaturedProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNodeArgs = {
  id: Scalars['ID']['input'];
};


export type QueryOrderArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  entityId: Scalars['Int']['input'];
};


export type QueryOrdersArgs = {
  params?: InputMaybe<GetOrdersParams>;
};


export type QueryPreviouslyOrderedProductsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryProductRecommendationsArgs = {
  countryId: Scalars['Int']['input'];
  journalId: Scalars['Int']['input'];
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  brand?: InputMaybe<Scalars['Int']['input']>;
  categories?: InputMaybe<Array<Scalars['Int']['input']>>;
  direction?: InputMaybe<Scalars['String']['input']>;
  entityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
  first?: InputMaybe<Scalars['Int']['input']>;
  hideOutOfStock?: InputMaybe<Scalars['Boolean']['input']>;
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
  includeSubresources?: InputMaybe<Scalars['Boolean']['input']>;
  showDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  showHidden?: InputMaybe<Scalars['Boolean']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRouteArgs = {
  path: Scalars['String']['input'];
};


export type QuerySubscriptionArgs = {
  entityId: Scalars['Int']['input'];
};

export type RelatedProductsConnection = {
  __typename?: 'RelatedProductsConnection';
  edges?: Maybe<Array<Maybe<RelatedProductsEdge>>>;
  pageInfo: PageInfo;
};

export type RelatedProductsEdge = {
  __typename?: 'RelatedProductsEdge';
  cursor: Scalars['String']['output'];
  node: Product;
};

export type ResumeSubscriptionResult = {
  __typename?: 'ResumeSubscriptionResult';
  nextShipmentDate?: Maybe<Scalars['DateTime']['output']>;
  subscription?: Maybe<Subscription>;
};

export type Reviews = {
  __typename?: 'Reviews';
  numberOfReviews: Scalars['Int']['output'];
  summationOfRatings: Scalars['Int']['output'];
};

export type Route = {
  __typename?: 'Route';
  node?: Maybe<Node>;
};

export type SelectedVariantOption = {
  __typename?: 'SelectedVariantOption';
  name?: Maybe<Scalars['String']['output']>;
  nameId?: Maybe<Scalars['Int']['output']>;
  value?: Maybe<Scalars['String']['output']>;
  valueId?: Maybe<Scalars['Int']['output']>;
};

export type Settings = {
  __typename?: 'Settings';
  channelId: Scalars['Long']['output'];
  contact?: Maybe<ContactField>;
  display: DisplayField;
  logo: LogoField;
  status: StorefrontStatusType;
  storeHash: Scalars['String']['output'];
  storeName: Scalars['String']['output'];
  url: UrlField;
};

export type SimpleBrand = {
  __typename?: 'SimpleBrand';
  entityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  path: Scalars['String']['output'];
  slug: Scalars['String']['output'];
};

export enum StorefrontStatusType {
  Hibernation = 'HIBERNATION',
  Launched = 'LAUNCHED',
  Maintenance = 'MAINTENANCE',
  PreLaunch = 'PRE_LAUNCH'
}

export type Subscription = Node & {
  __typename?: 'Subscription';
  countryId: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  doorCode?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  frequencyInDays: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  initialOrderId: Scalars['Int']['output'];
  product?: Maybe<Product>;
  productId: Scalars['Int']['output'];
  quantity: Scalars['Int']['output'];
  shipments?: Maybe<SubscriptionShipmentConnection>;
  shippingAddress?: Maybe<Address>;
  shippingService?: Maybe<Scalars['String']['output']>;
  status: SubscriptionStatus;
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
  variantId: Scalars['Int']['output'];
};


export type SubscriptionShipmentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  shippingStatuses?: InputMaybe<Array<InputMaybe<SubscriptionShipmentShippingStatus>>>;
};

export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  edges: Array<SubscriptionEdge>;
  pageInfo?: Maybe<PageInfo>;
};

export type SubscriptionEdge = {
  __typename?: 'SubscriptionEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: Subscription;
};

export type SubscriptionShipment = Node & {
  __typename?: 'SubscriptionShipment';
  createdAt: Scalars['DateTime']['output'];
  entityId: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  orderId?: Maybe<Scalars['Int']['output']>;
  paymentStatus: SubscriptionShipmentPaymentStatus;
  shippingDate: Scalars['DateTime']['output'];
  shippingStatus: SubscriptionShipmentShippingStatus;
  subscriptionId: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type SubscriptionShipmentConnection = {
  __typename?: 'SubscriptionShipmentConnection';
  edges?: Maybe<Array<Maybe<SubscriptionShipmentEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type SubscriptionShipmentEdge = {
  __typename?: 'SubscriptionShipmentEdge';
  cursor?: Maybe<Scalars['String']['output']>;
  node: SubscriptionShipment;
};

export enum SubscriptionShipmentPaymentStatus {
  Failed = 'failed',
  Paid = 'paid',
  Pending = 'pending'
}

export enum SubscriptionShipmentShippingStatus {
  Cancelled = 'cancelled',
  Pending = 'pending',
  Processed = 'processed',
  Shipped = 'shipped'
}

export enum SubscriptionStatus {
  Active = 'active',
  Inactive = 'inactive',
  Paused = 'paused',
  Pending = 'pending'
}

export type SwatchOptionValue = CatalogProductOptionValue & {
  __typename?: 'SwatchOptionValue';
  entityId: Scalars['Int']['output'];
  hexColors: Array<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isDefault: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};


export type SwatchOptionValueImageUrlArgs = {
  height?: InputMaybe<Scalars['Int']['input']>;
  width: Scalars['Int']['input'];
};

export type TextFieldOption = CatalogProductOption & {
  __typename?: 'TextFieldOption';
  displayName: Scalars['String']['output'];
  entityId: Scalars['Int']['output'];
  isRequired: Scalars['Boolean']['output'];
};

export type UrlField = {
  __typename?: 'UrlField';
  cdnUrl: Scalars['String']['output'];
  vanityUrl: Scalars['String']['output'];
};

export type Variant = Node & {
  __typename?: 'Variant';
  bundle?: Maybe<Bundle>;
  campaign?: Maybe<Campaign>;
  defaultImage?: Maybe<Image>;
  depth?: Maybe<Measurement>;
  entityId: Scalars['Int']['output'];
  height?: Maybe<Measurement>;
  id: Scalars['ID']['output'];
  inventory?: Maybe<VariantInventory>;
  isPurchasable?: Maybe<Scalars['Boolean']['output']>;
  lowest30DaysPrice?: Maybe<Scalars['BigDecimal']['output']>;
  metafields: MetafieldConnection;
  options: OptionConnection;
  prices?: Maybe<VariantPrices>;
  productOptions: ProductOptionConnection;
  sku: Scalars['String']['output'];
  units?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Measurement>;
  width?: Maybe<Measurement>;
};


export type VariantMetafieldsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  keys?: InputMaybe<Array<Scalars['String']['input']>>;
  last?: InputMaybe<Scalars['Int']['input']>;
  namespace: Scalars['String']['input'];
};


export type VariantOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type VariantPricesArgs = {
  currencyCode?: InputMaybe<CurrencyCode>;
  includeTax?: InputMaybe<Scalars['Boolean']['input']>;
};


export type VariantProductOptionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};

export type VariantConnection = {
  __typename?: 'VariantConnection';
  edges?: Maybe<Array<Maybe<VariantEdge>>>;
  pageInfo: PageInfo;
};

export type VariantEdge = {
  __typename?: 'VariantEdge';
  cursor: Scalars['String']['output'];
  node: Variant;
};

export type VariantInventory = {
  __typename?: 'VariantInventory';
  aggregated?: Maybe<Aggregated>;
  byLocation?: Maybe<LocationConnection>;
  isInStock: Scalars['Boolean']['output'];
};


export type VariantInventoryByLocationArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  locationEntityIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type VariantPrices = Prices & {
  __typename?: 'VariantPrices';
  basePrice?: Maybe<Money>;
  price: Money;
  pricePerKilo?: Maybe<Money>;
  pricePerLiter?: Maybe<Money>;
  pricePerUnit?: Maybe<Money>;
  promoPrice?: Maybe<Money>;
  salePrice?: Maybe<Money>;
};

export type Video = {
  __typename?: 'Video';
  id?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  videoId?: Maybe<Scalars['String']['output']>;
};

export enum CurrencyCode {
  Adp = 'ADP',
  Aed = 'AED',
  Afa = 'AFA',
  Afn = 'AFN',
  Alk = 'ALK',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Aok = 'AOK',
  Aon = 'AON',
  Aor = 'AOR',
  Ara = 'ARA',
  Arl = 'ARL',
  Arm = 'ARM',
  Arp = 'ARP',
  Ars = 'ARS',
  Ats = 'ATS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azm = 'AZM',
  Azn = 'AZN',
  Bad = 'BAD',
  Bam = 'BAM',
  Ban = 'BAN',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bec = 'BEC',
  Bef = 'BEF',
  Bel = 'BEL',
  Bgl = 'BGL',
  Bgm = 'BGM',
  Bgn = 'BGN',
  Bgo = 'BGO',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bol = 'BOL',
  Bop = 'BOP',
  Bov = 'BOV',
  Brb = 'BRB',
  Brc = 'BRC',
  Bre = 'BRE',
  Brl = 'BRL',
  Brn = 'BRN',
  Brr = 'BRR',
  Brz = 'BRZ',
  Bsd = 'BSD',
  Btn = 'BTN',
  Buk = 'BUK',
  Bwp = 'BWP',
  Byb = 'BYB',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Cle = 'CLE',
  Clf = 'CLF',
  Clp = 'CLP',
  Cnx = 'CNX',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Csd = 'CSD',
  Csk = 'CSK',
  Cve = 'CVE',
  Cyp = 'CYP',
  Czk = 'CZK',
  Ddm = 'DDM',
  Dem = 'DEM',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Ecs = 'ECS',
  Ecv = 'ECV',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Esa = 'ESA',
  Esb = 'ESB',
  Esp = 'ESP',
  Etb = 'ETB',
  Eur = 'EUR',
  Fim = 'FIM',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Frf = 'FRF',
  Gbp = 'GBP',
  Gek = 'GEK',
  Gel = 'GEL',
  Ghc = 'GHC',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gns = 'GNS',
  Gqe = 'GQE',
  Grd = 'GRD',
  Gtq = 'GTQ',
  Gwe = 'GWE',
  Gwp = 'GWP',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrd = 'HRD',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Iep = 'IEP',
  Ilp = 'ILP',
  Ilr = 'ILR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Isj = 'ISJ',
  Isk = 'ISK',
  Itl = 'ITL',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Krh = 'KRH',
  Kro = 'KRO',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Ltt = 'LTT',
  Luc = 'LUC',
  Luf = 'LUF',
  Lul = 'LUL',
  Lvl = 'LVL',
  Lvr = 'LVR',
  Lyd = 'LYD',
  Mad = 'MAD',
  Maf = 'MAF',
  Mcf = 'MCF',
  Mdc = 'MDC',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mgf = 'MGF',
  Mkd = 'MKD',
  Mkn = 'MKN',
  Mlf = 'MLF',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mtl = 'MTL',
  Mtp = 'MTP',
  Mur = 'MUR',
  Mvp = 'MVP',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxp = 'MXP',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mze = 'MZE',
  Mzm = 'MZM',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nic = 'NIC',
  Nio = 'NIO',
  Nlg = 'NLG',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pei = 'PEI',
  Pen = 'PEN',
  Pes = 'PES',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Plz = 'PLZ',
  Pte = 'PTE',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rhd = 'RHD',
  Rol = 'ROL',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdd = 'SDD',
  Sdg = 'SDG',
  Sdp = 'SDP',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sit = 'SIT',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Srg = 'SRG',
  Ssp = 'SSP',
  Std = 'STD',
  Sur = 'SUR',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjr = 'TJR',
  Tjs = 'TJS',
  Tmm = 'TMM',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Tpe = 'TPE',
  Trl = 'TRL',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Uak = 'UAK',
  Ugs = 'UGS',
  Ugx = 'UGX',
  Usd = 'USD',
  Usn = 'USN',
  Uss = 'USS',
  Uyi = 'UYI',
  Uyp = 'UYP',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vef = 'VEF',
  Vnd = 'VND',
  Vnn = 'VNN',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xcd = 'XCD',
  Xeu = 'XEU',
  Xfo = 'XFO',
  Xfu = 'XFU',
  Xof = 'XOF',
  Xpf = 'XPF',
  Xre = 'XRE',
  Ydd = 'YDD',
  Yer = 'YER',
  Yud = 'YUD',
  Yum = 'YUM',
  Yun = 'YUN',
  Yur = 'YUR',
  Zal = 'ZAL',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zmw = 'ZMW',
  Zrn = 'ZRN',
  Zrz = 'ZRZ',
  Zwd = 'ZWD',
  Zwl = 'ZWL',
  Zwr = 'ZWR'
}
