import { defineStore } from 'pinia';

export const useSubscriptionsReceiptStore = defineStore(
  'subscriptionsReceipt',
  () => {
    const receipts = ref<{ checkoutId: string; products: any[] }[]>([]);

    return { receipts };
  },
  {
    // @ts-ignore - should pick the type from @pinia-plugin-persistedstate/nuxt, but doesn't seem to work
    persist: {
      // @ts-ignore - should pick the type from @pinia-plugin-persistedstate/nuxt, but doesn't seem to work
      storage: persistedState.sessionStorage,
    },
  }
);
