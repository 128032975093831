<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <Html :lang="head.htmlAttrs?.lang" :dir="head.htmlAttrs?.dir">
    <Head>
      <Title>{{ title }}</Title>
      <Meta name="description" :content="description" />

      <Link
        v-if="canonicalLink"
        :id="canonicalLink.id"
        :rel="canonicalLink.rel"
        :href="canonicalLink.href"
        :hreflang="canonicalLink.hreflang"
      />

      <Meta
        v-if="ogUrl"
        :id="ogUrl.id"
        :property="ogUrl.property"
        :content="ogUrl.content"
      />

      <Meta
        v-if="ogLocale"
        :id="ogLocale.id"
        :property="ogLocale.property"
        :content="ogLocale.content"
      />

      <template v-for="meta in metaTagsFromCraft" :key="meta.id">
        <Meta
          :id="meta.id"
          :name="meta.name"
          :property="meta.property"
          :content="meta.content"
        />
      </template>

      <template v-if="hrefLangLinks && showHrefLangLinks">
        <template v-for="link in hrefLangLinks" :key="link.id">
          <Link
            :id="link.id"
            :rel="link.rel"
            :href="link.href"
            :hreflang="link.hreflang"
          />
        </template>
        <template v-for="meta in ogLocaleAlternate" :key="meta.id">
          <Meta
            :id="meta.id"
            :property="meta.property"
            :content="meta.content"
          />
        </template>
      </template>
    </Head>
  </Html>
</template>

<script setup lang="ts">
const props = defineProps({
  page: {
    type: Object,
    default: () => {},
  },
  title: {
    type: String,
    default: '',
  },
  seoTitle: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  extraJsonLd: {
    type: Object,
    default: () => ({}),
  },
});

const route = useRoute();
const runtimeConfig = useRuntimeConfig();

/* if the route has params, like a slug, id or something else we don't want to add hreflang links */
const showHrefLangLinks = computed(
  () =>
    Object.keys(route.params).length <= 0 &&
    Object.keys(route.query).length <= 0
);

type SeoMeta = {
  id: string;
  name: string;
  property: string;
  content: string;
};

const { page } = props;

const title = computed(() => {
  if (props.seoTitle) {
    return props.seoTitle;
  }

  const { brandName } = runtimeConfig.public;

  if (props.title) {
    return `${props?.title?.trim()} | ${brandName}`;
  }
  return page?.seoTitle || `${page?.title} | ${brandName}`;
});

const description = computed(() => {
  if (props.description) {
    const text = simpleStringWithoutHtml(props.description);
    return simpleTruncate(text, 160);
  }
  const seoDescription = page?.seoMeta?.find(
    (meta: SeoMeta) => meta.name === 'description'
  )?.content;

  if (seoDescription) {
    const text = simpleStringWithoutHtml(seoDescription);
    return simpleTruncate(text, 160);
  }
  return '';
});

const metaTagsFromCraft = computed(() =>
  page?.seoMeta?.filter((meta: SeoMeta) => meta.name !== 'description')
);

const head = useLocaleHead({
  addDirAttribute: true,
  identifierAttribute: 'id',
  addSeoAttributes: {
    canonicalQueries: ['page'],
  },
});

const canonicalLink: any = computed(
  () => head.value.link?.filter((item) => item.rel === 'canonical')[0]
);

const ogUrl = computed(
  () => head.value.meta?.filter((meta) => meta.property === 'og:url')[0]
);

const ogLocale = computed(
  () => head.value.meta?.filter((meta) => meta.property === 'og:locale')[0]
);

const ogLocaleAlternate = computed(() =>
  head.value.meta?.filter((meta) => meta.property === 'og:locale:alternate')
);

const hrefLangLinks: any = computed(() =>
  head.value.link?.filter(
    (item) => item.rel === 'alternate' && item?.hreflang?.includes('-')
  )
);

const craftJsonLd = computed(() => {
  const jsonLd = page?.jsonLd;
  if (jsonLd) {
    return JSON.parse(jsonLd);
  }
  return {};
});

const combinedJsonLd = computed(() => {
  const clonedJsonLd = { ...craftJsonLd.value };
  if (clonedJsonLd?.['@graph']) {
    clonedJsonLd['@graph'] = [...clonedJsonLd['@graph'], props.extraJsonLd];
  } else {
    clonedJsonLd['@graph'] = [props.extraJsonLd];
  }
  return clonedJsonLd;
});

useHead({
  script: page?.jsonLd
    ? [
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(combinedJsonLd.value),
        },
      ]
    : [],
});

const simpleStringWithoutHtml = (str: string) => str.replace(/<[^>]*>/g, '');

const simpleTruncate = (str: string, n: number) =>
  str.length <= n
    ? str
    : `${str.slice(0, n - 1).slice(0, str.slice(0, n - 1).lastIndexOf(' '))}…`;
</script>
