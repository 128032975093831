import { default as _91_91slug_93_934tdfrW3ROpMeta } from "/home/node/client/.firstvet/pages/[[slug]].vue?macro=true";
import { default as _91_91slug_93_93meAR83q3WRMeta } from "/home/node/client/.firstvet/pages/app/[[slug]].vue?macro=true";
import { default as cookies3TmnwgVt0aMeta } from "/home/node/client/.firstvet/pages/app/cookies.vue?macro=true";
import { default as indexvqou7OL24yMeta } from "/home/node/client/.firstvet/pages/app/ppcontacts/index.vue?macro=true";
import { default as _91_91slug_93_93v7BY6WWw5mMeta } from "/home/node/client/.firstvet/pages/articles/[[slug]].vue?macro=true";
import { default as _91_91slug_93_93noza1PpULmMeta } from "/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue?macro=true";
import { default as _91_46_46_46slug_93tyaMd9oEZgMeta } from "/home/node/client/.firstvet/pages/articles/author/[...slug].vue?macro=true";
import { default as indexNSBQ4DrXtJMeta } from "/home/node/client/.firstvet/pages/articles/author/index.vue?macro=true";
import { default as indexSj0gN9ToG9Meta } from "/home/node/client/.firstvet/pages/articles/index.vue?macro=true";
import { default as _91_91slug_93_93vcVBWlzeXiMeta } from "/home/node/client/.firstvet/pages/city/[[slug]].vue?macro=true";
import { default as indexCKG1JbZsRBMeta } from "/home/node/client/.firstvet/pages/cookies/index.vue?macro=true";
import { default as country_45selectRUZ8OV9dL5Meta } from "/home/node/client/pages/country-select.vue?macro=true";
import { default as indexQmeS472sJIMeta } from "/home/node/client/.firstvet/pages/index.vue?macro=true";
import { default as _91_91slug_93_93zxN0AQPn0qMeta } from "/home/node/client/.firstvet/pages/insurance/[[slug]].vue?macro=true";
import { default as indexrGGK5c7L15Meta } from "/home/node/client/.firstvet/pages/insurance/index.vue?macro=true";
import { default as _91_91slug_93_93Wki3kTUIcEMeta } from "/home/node/client/.firstvet/pages/jobs/[[slug]].vue?macro=true";
import { default as indexsI7gVdPwDlMeta } from "/home/node/client/.firstvet/pages/jobs/index.vue?macro=true";
import { default as _91_91slug_93_935ZPROMWTytMeta } from "/home/node/client/.firstvet/pages/partner/[[slug]].vue?macro=true";
import { default as index1I6ece1RfXMeta } from "/home/node/client/.firstvet/pages/partner/index.vue?macro=true";
import { default as indexkw7BFYJcRJMeta } from "/home/node/client/.firstvet/pages/ppcontacts/index.vue?macro=true";
import { default as indexYFq9PSOw4PMeta } from "/home/node/client/.firstvet/pages/ppja/index.vue?macro=true";
import { default as indexYMkMgv9Sb8Meta } from "/home/node/client/.firstvet/pages/press/index.vue?macro=true";
import { default as _91_91slug_93_93EAk2oHQDjIMeta } from "/home/node/client/.firstvet/pages/press/pr/[[slug]].vue?macro=true";
import { default as pricOJhDGaSfMeta } from "/home/node/client/.firstvet/pages/press/pr/pr.vue?macro=true";
import { default as indexHKSv4Uf9U3Meta } from "/home/node/client/.firstvet/pages/privacy-policy/index.vue?macro=true";
import { default as _91_91slug_93_93kR4dFt4x9xMeta } from "/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue?macro=true";
import { default as indexCbu0Vwv5dAMeta } from "/home/node/client/.firstvet/pages/questions/index.vue?macro=true";
import { default as _91_46_46_46slug_93mSs9Smo4WhMeta } from "/home/node/client/pages/shop/[...slug].vue?macro=true";
import { default as indexXFHzzTqn00Meta } from "/home/node/client/pages/shop/advent-calendar/index.vue?macro=true";
import { default as _91_46_46_46slug_93F0vGeFjjkGMeta } from "/home/node/client/pages/shop/brands/[...slug].vue?macro=true";
import { default as indexaRZzOQuEw3Meta } from "/home/node/client/pages/shop/brands/index.vue?macro=true";
import { default as _91_46_46_46slug_930OZamWOekBMeta } from "/home/node/client/pages/shop/campaigns/[...slug].vue?macro=true";
import { default as _91animalType_93JIT39z5rPOMeta } from "/home/node/client/pages/shop/campaigns/black-week/[animalType].vue?macro=true";
import { default as indexB5pyBKNhFeMeta } from "/home/node/client/pages/shop/campaigns/black-week/index.vue?macro=true";
import { default as indexs9y6zoe1NiMeta } from "/home/node/client/pages/shop/campaigns/index.vue?macro=true";
import { default as checkoutXiXOBYjJz7Meta } from "/home/node/client/pages/shop/checkout.vue?macro=true";
import { default as indexSF2jPFF0YxMeta } from "/home/node/client/pages/shop/dashboard/account-settings/index.vue?macro=true";
import { default as personal_45informationK1lrgxJGRMMeta } from "/home/node/client/pages/shop/dashboard/account-settings/personal-information.vue?macro=true";
import { default as _91id_93ziX0D1pBrVMeta } from "/home/node/client/pages/shop/dashboard/order-history/[id].vue?macro=true";
import { default as index8vj6lGFTH8Meta } from "/home/node/client/pages/shop/dashboard/order-history/index.vue?macro=true";
import { default as prescriptionsQe26GYsy7GMeta } from "/home/node/client/pages/shop/dashboard/prescriptions.vue?macro=true";
import { default as returnsB7TT9o8xldMeta } from "/home/node/client/pages/shop/dashboard/returns.vue?macro=true";
import { default as _91id_933uJOlwtu77Meta } from "/home/node/client/pages/shop/dashboard/subscriptions/[id].vue?macro=true";
import { default as indexzuRYw9xbQDMeta } from "/home/node/client/pages/shop/dashboard/subscriptions/index.vue?macro=true";
import { default as dashboardbeOZBhndB4Meta } from "/home/node/client/pages/shop/dashboard.vue?macro=true";
import { default as favoritesbKhaaTBmfxMeta } from "/home/node/client/pages/shop/favorites.vue?macro=true";
import { default as food_45consultationonyIXKSevZMeta } from "/home/node/client/pages/shop/food-consultation.vue?macro=true";
import { default as health_45checkpdU3eIDtRTMeta } from "/home/node/client/pages/shop/health-check.vue?macro=true";
import { default as indextobIe28DMUMeta } from "/home/node/client/pages/shop/index.vue?macro=true";
import { default as _91_46_46_46slug_93eiB3AJYl2eMeta } from "/home/node/client/pages/shop/info/[...slug].vue?macro=true";
import { default as indexJEMBUpuFjLMeta } from "/home/node/client/pages/shop/info/index.vue?macro=true";
import { default as loginRiyJwdfslsMeta } from "/home/node/client/pages/shop/login.vue?macro=true";
import { default as order_45returnuDQiAvkmEeMeta } from "/home/node/client/pages/shop/order-return.vue?macro=true";
import { default as parasite_45testsnCsFaM7YlsMeta } from "/home/node/client/.firstvet/pages/shop/parasite-tests.vue?macro=true";
import { default as _91_46_46_46slug_93qetc8SIO1oMeta } from "/home/node/client/pages/shop/prescription/[brand]/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93h6whS9ooitMeta } from "/home/node/client/pages/shop/product/[...slug].vue?macro=true";
import { default as receiptKOmvqH7SylMeta } from "/home/node/client/pages/shop/receipt.vue?macro=true";
import { default as recommended_45productsl4SJFm3O2lMeta } from "/home/node/client/pages/shop/recommended-products.vue?macro=true";
import { default as searchmBXBBJA9x8Meta } from "/home/node/client/pages/shop/search.vue?macro=true";
import { default as sitemapWOENLFgwrXMeta } from "/home/node/client/.firstvet/pages/sitemap.vue?macro=true";
import { default as index5cfFJ2KABbMeta } from "/home/node/client/.firstvet/pages/symptom/index.vue?macro=true";
import { default as indexsHv6tmKpEWMeta } from "/home/node/client/.firstvet/pages/terms-conditions/index.vue?macro=true";
export default [
  {
    name: _91_91slug_93_934tdfrW3ROpMeta?.name ?? "slug___dk",
    path: _91_91slug_93_934tdfrW3ROpMeta?.path ?? "/dk/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    alias: _91_91slug_93_934tdfrW3ROpMeta?.alias || [],
    redirect: _91_91slug_93_934tdfrW3ROpMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_934tdfrW3ROpMeta?.name ?? "slug___de",
    path: _91_91slug_93_934tdfrW3ROpMeta?.path ?? "/de/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    alias: _91_91slug_93_934tdfrW3ROpMeta?.alias || [],
    redirect: _91_91slug_93_934tdfrW3ROpMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_934tdfrW3ROpMeta?.name ?? "slug___uk",
    path: _91_91slug_93_934tdfrW3ROpMeta?.path ?? "/uk/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    alias: _91_91slug_93_934tdfrW3ROpMeta?.alias || [],
    redirect: _91_91slug_93_934tdfrW3ROpMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_934tdfrW3ROpMeta?.name ?? "slug___us",
    path: _91_91slug_93_934tdfrW3ROpMeta?.path ?? "/us/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    alias: _91_91slug_93_934tdfrW3ROpMeta?.alias || [],
    redirect: _91_91slug_93_934tdfrW3ROpMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_934tdfrW3ROpMeta?.name ?? "slug___fi",
    path: _91_91slug_93_934tdfrW3ROpMeta?.path ?? "/fi/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    alias: _91_91slug_93_934tdfrW3ROpMeta?.alias || [],
    redirect: _91_91slug_93_934tdfrW3ROpMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_934tdfrW3ROpMeta?.name ?? "slug___no",
    path: _91_91slug_93_934tdfrW3ROpMeta?.path ?? "/no/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    alias: _91_91slug_93_934tdfrW3ROpMeta?.alias || [],
    redirect: _91_91slug_93_934tdfrW3ROpMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_934tdfrW3ROpMeta?.name ?? "slug___sv",
    path: _91_91slug_93_934tdfrW3ROpMeta?.path ?? "/sv/:slug(.*)*",
    meta: _91_91slug_93_934tdfrW3ROpMeta || {},
    alias: _91_91slug_93_934tdfrW3ROpMeta?.alias || [],
    redirect: _91_91slug_93_934tdfrW3ROpMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93meAR83q3WRMeta?.name ?? "app-slug___dk",
    path: _91_91slug_93_93meAR83q3WRMeta?.path ?? "/dk/app/:slug",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    alias: _91_91slug_93_93meAR83q3WRMeta?.alias || [],
    redirect: _91_91slug_93_93meAR83q3WRMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93meAR83q3WRMeta?.name ?? "app-slug___de",
    path: _91_91slug_93_93meAR83q3WRMeta?.path ?? "/de/app/:slug",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    alias: _91_91slug_93_93meAR83q3WRMeta?.alias || [],
    redirect: _91_91slug_93_93meAR83q3WRMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93meAR83q3WRMeta?.name ?? "app-slug___uk",
    path: _91_91slug_93_93meAR83q3WRMeta?.path ?? "/uk/app/:slug",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    alias: _91_91slug_93_93meAR83q3WRMeta?.alias || [],
    redirect: _91_91slug_93_93meAR83q3WRMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93meAR83q3WRMeta?.name ?? "app-slug___us",
    path: _91_91slug_93_93meAR83q3WRMeta?.path ?? "/us/app/:slug",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    alias: _91_91slug_93_93meAR83q3WRMeta?.alias || [],
    redirect: _91_91slug_93_93meAR83q3WRMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93meAR83q3WRMeta?.name ?? "app-slug___fi",
    path: _91_91slug_93_93meAR83q3WRMeta?.path ?? "/fi/app/:slug",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    alias: _91_91slug_93_93meAR83q3WRMeta?.alias || [],
    redirect: _91_91slug_93_93meAR83q3WRMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93meAR83q3WRMeta?.name ?? "app-slug___no",
    path: _91_91slug_93_93meAR83q3WRMeta?.path ?? "/no/app/:slug",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    alias: _91_91slug_93_93meAR83q3WRMeta?.alias || [],
    redirect: _91_91slug_93_93meAR83q3WRMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93meAR83q3WRMeta?.name ?? "app-slug___sv",
    path: _91_91slug_93_93meAR83q3WRMeta?.path ?? "/sv/apps/:slug",
    meta: _91_91slug_93_93meAR83q3WRMeta || {},
    alias: _91_91slug_93_93meAR83q3WRMeta?.alias || [],
    redirect: _91_91slug_93_93meAR83q3WRMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: cookies3TmnwgVt0aMeta?.name ?? "app-cookies___dk",
    path: cookies3TmnwgVt0aMeta?.path ?? "/dk/app/cookies",
    url: "/dk/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    alias: cookies3TmnwgVt0aMeta?.alias || [],
    redirect: cookies3TmnwgVt0aMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies3TmnwgVt0aMeta?.name ?? "app-cookies___de",
    path: cookies3TmnwgVt0aMeta?.path ?? "/de/app/cookies",
    url: "/de/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    alias: cookies3TmnwgVt0aMeta?.alias || [],
    redirect: cookies3TmnwgVt0aMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies3TmnwgVt0aMeta?.name ?? "app-cookies___uk",
    path: cookies3TmnwgVt0aMeta?.path ?? "/uk/app/cookies",
    url: "/uk/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    alias: cookies3TmnwgVt0aMeta?.alias || [],
    redirect: cookies3TmnwgVt0aMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies3TmnwgVt0aMeta?.name ?? "app-cookies___us",
    path: cookies3TmnwgVt0aMeta?.path ?? "/us/app/cookies",
    url: "/us/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    alias: cookies3TmnwgVt0aMeta?.alias || [],
    redirect: cookies3TmnwgVt0aMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies3TmnwgVt0aMeta?.name ?? "app-cookies___fi",
    path: cookies3TmnwgVt0aMeta?.path ?? "/fi/app/cookies",
    url: "/fi/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    alias: cookies3TmnwgVt0aMeta?.alias || [],
    redirect: cookies3TmnwgVt0aMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies3TmnwgVt0aMeta?.name ?? "app-cookies___no",
    path: cookies3TmnwgVt0aMeta?.path ?? "/no/app/cookies",
    url: "/no/app/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    alias: cookies3TmnwgVt0aMeta?.alias || [],
    redirect: cookies3TmnwgVt0aMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: cookies3TmnwgVt0aMeta?.name ?? "app-cookies___sv",
    path: cookies3TmnwgVt0aMeta?.path ?? "/sv/apps/cookies",
    url: "/sv/apps/cookies",
    meta: cookies3TmnwgVt0aMeta || {},
    alias: cookies3TmnwgVt0aMeta?.alias || [],
    redirect: cookies3TmnwgVt0aMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexvqou7OL24yMeta?.name ?? "app-ppcontacts___dk",
    path: indexvqou7OL24yMeta?.path ?? "/dk/ppcontacts",
    url: "/dk/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    alias: indexvqou7OL24yMeta?.alias || [],
    redirect: indexvqou7OL24yMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqou7OL24yMeta?.name ?? "app-ppcontacts___de",
    path: indexvqou7OL24yMeta?.path ?? "/de/ppcontacts",
    url: "/de/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    alias: indexvqou7OL24yMeta?.alias || [],
    redirect: indexvqou7OL24yMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqou7OL24yMeta?.name ?? "app-ppcontacts___uk",
    path: indexvqou7OL24yMeta?.path ?? "/uk/ppcontacts",
    url: "/uk/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    alias: indexvqou7OL24yMeta?.alias || [],
    redirect: indexvqou7OL24yMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqou7OL24yMeta?.name ?? "app-ppcontacts___us",
    path: indexvqou7OL24yMeta?.path ?? "/us/ppcontacts",
    url: "/us/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    alias: indexvqou7OL24yMeta?.alias || [],
    redirect: indexvqou7OL24yMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqou7OL24yMeta?.name ?? "app-ppcontacts___fi",
    path: indexvqou7OL24yMeta?.path ?? "/fi/ppcontacts",
    url: "/fi/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    alias: indexvqou7OL24yMeta?.alias || [],
    redirect: indexvqou7OL24yMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqou7OL24yMeta?.name ?? "app-ppcontacts___no",
    path: indexvqou7OL24yMeta?.path ?? "/no/ppcontacts",
    url: "/no/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    alias: indexvqou7OL24yMeta?.alias || [],
    redirect: indexvqou7OL24yMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexvqou7OL24yMeta?.name ?? "app-ppcontacts___sv",
    path: indexvqou7OL24yMeta?.path ?? "/sv/ppcontacts",
    url: "/sv/ppcontacts",
    meta: indexvqou7OL24yMeta || {},
    alias: indexvqou7OL24yMeta?.alias || [],
    redirect: indexvqou7OL24yMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/app/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93v7BY6WWw5mMeta?.name ?? "articles-slug___dk",
    path: _91_91slug_93_93v7BY6WWw5mMeta?.path ?? "/dk/artikler/:slug",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    alias: _91_91slug_93_93v7BY6WWw5mMeta?.alias || [],
    redirect: _91_91slug_93_93v7BY6WWw5mMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93v7BY6WWw5mMeta?.name ?? "articles-slug___de",
    path: _91_91slug_93_93v7BY6WWw5mMeta?.path ?? "/de/artikel/:slug",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    alias: _91_91slug_93_93v7BY6WWw5mMeta?.alias || [],
    redirect: _91_91slug_93_93v7BY6WWw5mMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93v7BY6WWw5mMeta?.name ?? "articles-slug___uk",
    path: _91_91slug_93_93v7BY6WWw5mMeta?.path ?? "/uk/articles/:slug",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    alias: _91_91slug_93_93v7BY6WWw5mMeta?.alias || [],
    redirect: _91_91slug_93_93v7BY6WWw5mMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93v7BY6WWw5mMeta?.name ?? "articles-slug___us",
    path: _91_91slug_93_93v7BY6WWw5mMeta?.path ?? "/us/articles/:slug",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    alias: _91_91slug_93_93v7BY6WWw5mMeta?.alias || [],
    redirect: _91_91slug_93_93v7BY6WWw5mMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93v7BY6WWw5mMeta?.name ?? "articles-slug___fi",
    path: _91_91slug_93_93v7BY6WWw5mMeta?.path ?? "/fi/artikkeleita/:slug",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    alias: _91_91slug_93_93v7BY6WWw5mMeta?.alias || [],
    redirect: _91_91slug_93_93v7BY6WWw5mMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93v7BY6WWw5mMeta?.name ?? "articles-slug___no",
    path: _91_91slug_93_93v7BY6WWw5mMeta?.path ?? "/no/artikler/:slug",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    alias: _91_91slug_93_93v7BY6WWw5mMeta?.alias || [],
    redirect: _91_91slug_93_93v7BY6WWw5mMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93v7BY6WWw5mMeta?.name ?? "articles-slug___sv",
    path: _91_91slug_93_93v7BY6WWw5mMeta?.path ?? "/sv/artiklar/:slug",
    meta: _91_91slug_93_93v7BY6WWw5mMeta || {},
    alias: _91_91slug_93_93v7BY6WWw5mMeta?.alias || [],
    redirect: _91_91slug_93_93v7BY6WWw5mMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93noza1PpULmMeta?.name ?? "articles-animal-slug___dk",
    path: _91_91slug_93_93noza1PpULmMeta?.path ?? "/dk/artikler/dyr/:slug",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    alias: _91_91slug_93_93noza1PpULmMeta?.alias || [],
    redirect: _91_91slug_93_93noza1PpULmMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93noza1PpULmMeta?.name ?? "articles-animal-slug___de",
    path: _91_91slug_93_93noza1PpULmMeta?.path ?? "/de/artikel/tier/:slug",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    alias: _91_91slug_93_93noza1PpULmMeta?.alias || [],
    redirect: _91_91slug_93_93noza1PpULmMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93noza1PpULmMeta?.name ?? "articles-animal-slug___uk",
    path: _91_91slug_93_93noza1PpULmMeta?.path ?? "/uk/articles/animal/:slug",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    alias: _91_91slug_93_93noza1PpULmMeta?.alias || [],
    redirect: _91_91slug_93_93noza1PpULmMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93noza1PpULmMeta?.name ?? "articles-animal-slug___us",
    path: _91_91slug_93_93noza1PpULmMeta?.path ?? "/us/articles/animal/:slug",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    alias: _91_91slug_93_93noza1PpULmMeta?.alias || [],
    redirect: _91_91slug_93_93noza1PpULmMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93noza1PpULmMeta?.name ?? "articles-animal-slug___fi",
    path: _91_91slug_93_93noza1PpULmMeta?.path ?? "/fi/artikkeleita/elain/:slug",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    alias: _91_91slug_93_93noza1PpULmMeta?.alias || [],
    redirect: _91_91slug_93_93noza1PpULmMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93noza1PpULmMeta?.name ?? "articles-animal-slug___no",
    path: _91_91slug_93_93noza1PpULmMeta?.path ?? "/no/artikler/dyr/:slug",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    alias: _91_91slug_93_93noza1PpULmMeta?.alias || [],
    redirect: _91_91slug_93_93noza1PpULmMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93noza1PpULmMeta?.name ?? "articles-animal-slug___sv",
    path: _91_91slug_93_93noza1PpULmMeta?.path ?? "/sv/artiklar/djur/:slug",
    meta: _91_91slug_93_93noza1PpULmMeta || {},
    alias: _91_91slug_93_93noza1PpULmMeta?.alias || [],
    redirect: _91_91slug_93_93noza1PpULmMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/animal/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tyaMd9oEZgMeta?.name ?? "articles-author-slug___dk",
    path: _91_46_46_46slug_93tyaMd9oEZgMeta?.path ?? "/dk/artikler/forfatter/:slug",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    alias: _91_46_46_46slug_93tyaMd9oEZgMeta?.alias || [],
    redirect: _91_46_46_46slug_93tyaMd9oEZgMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tyaMd9oEZgMeta?.name ?? "articles-author-slug___de",
    path: _91_46_46_46slug_93tyaMd9oEZgMeta?.path ?? "/de/artikel/autoren/:slug",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    alias: _91_46_46_46slug_93tyaMd9oEZgMeta?.alias || [],
    redirect: _91_46_46_46slug_93tyaMd9oEZgMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tyaMd9oEZgMeta?.name ?? "articles-author-slug___uk",
    path: _91_46_46_46slug_93tyaMd9oEZgMeta?.path ?? "/uk/articles/author/:slug",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    alias: _91_46_46_46slug_93tyaMd9oEZgMeta?.alias || [],
    redirect: _91_46_46_46slug_93tyaMd9oEZgMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tyaMd9oEZgMeta?.name ?? "articles-author-slug___us",
    path: _91_46_46_46slug_93tyaMd9oEZgMeta?.path ?? "/us/articles/author/:slug",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    alias: _91_46_46_46slug_93tyaMd9oEZgMeta?.alias || [],
    redirect: _91_46_46_46slug_93tyaMd9oEZgMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tyaMd9oEZgMeta?.name ?? "articles-author-slug___fi",
    path: _91_46_46_46slug_93tyaMd9oEZgMeta?.path ?? "/fi/artikkeleita/kirjoittaja/:slug",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    alias: _91_46_46_46slug_93tyaMd9oEZgMeta?.alias || [],
    redirect: _91_46_46_46slug_93tyaMd9oEZgMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tyaMd9oEZgMeta?.name ?? "articles-author-slug___no",
    path: _91_46_46_46slug_93tyaMd9oEZgMeta?.path ?? "/no/artikler/forfatter/:slug",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    alias: _91_46_46_46slug_93tyaMd9oEZgMeta?.alias || [],
    redirect: _91_46_46_46slug_93tyaMd9oEZgMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tyaMd9oEZgMeta?.name ?? "articles-author-slug___sv",
    path: _91_46_46_46slug_93tyaMd9oEZgMeta?.path ?? "/sv/artiklar/skribent/:slug",
    meta: _91_46_46_46slug_93tyaMd9oEZgMeta || {},
    alias: _91_46_46_46slug_93tyaMd9oEZgMeta?.alias || [],
    redirect: _91_46_46_46slug_93tyaMd9oEZgMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexNSBQ4DrXtJMeta?.name ?? "articles-author___dk",
    path: indexNSBQ4DrXtJMeta?.path ?? "/dk/artikler/forfatter",
    url: "/dk/artikler/forfatter",
    meta: indexNSBQ4DrXtJMeta || {},
    alias: indexNSBQ4DrXtJMeta?.alias || [],
    redirect: indexNSBQ4DrXtJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSBQ4DrXtJMeta?.name ?? "articles-author___de",
    path: indexNSBQ4DrXtJMeta?.path ?? "/de/artikel/autoren",
    url: "/de/artikel/autoren",
    meta: indexNSBQ4DrXtJMeta || {},
    alias: indexNSBQ4DrXtJMeta?.alias || [],
    redirect: indexNSBQ4DrXtJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSBQ4DrXtJMeta?.name ?? "articles-author___uk",
    path: indexNSBQ4DrXtJMeta?.path ?? "/uk/articles/author",
    url: "/uk/articles/author",
    meta: indexNSBQ4DrXtJMeta || {},
    alias: indexNSBQ4DrXtJMeta?.alias || [],
    redirect: indexNSBQ4DrXtJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSBQ4DrXtJMeta?.name ?? "articles-author___us",
    path: indexNSBQ4DrXtJMeta?.path ?? "/us/articles/author",
    url: "/us/articles/author",
    meta: indexNSBQ4DrXtJMeta || {},
    alias: indexNSBQ4DrXtJMeta?.alias || [],
    redirect: indexNSBQ4DrXtJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSBQ4DrXtJMeta?.name ?? "articles-author___fi",
    path: indexNSBQ4DrXtJMeta?.path ?? "/fi/artikkeleita/kirjoittaja",
    url: "/fi/artikkeleita/kirjoittaja",
    meta: indexNSBQ4DrXtJMeta || {},
    alias: indexNSBQ4DrXtJMeta?.alias || [],
    redirect: indexNSBQ4DrXtJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSBQ4DrXtJMeta?.name ?? "articles-author___no",
    path: indexNSBQ4DrXtJMeta?.path ?? "/no/artikler/forfatter",
    url: "/no/artikler/forfatter",
    meta: indexNSBQ4DrXtJMeta || {},
    alias: indexNSBQ4DrXtJMeta?.alias || [],
    redirect: indexNSBQ4DrXtJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: indexNSBQ4DrXtJMeta?.name ?? "articles-author___sv",
    path: indexNSBQ4DrXtJMeta?.path ?? "/sv/artiklar/skribent",
    url: "/sv/artiklar/skribent",
    meta: indexNSBQ4DrXtJMeta || {},
    alias: indexNSBQ4DrXtJMeta?.alias || [],
    redirect: indexNSBQ4DrXtJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/author/index.vue").then(m => m.default || m)
  },
  {
    name: indexSj0gN9ToG9Meta?.name ?? "articles___dk",
    path: indexSj0gN9ToG9Meta?.path ?? "/dk/artikler",
    url: "/dk/artikler",
    meta: indexSj0gN9ToG9Meta || {},
    alias: indexSj0gN9ToG9Meta?.alias || [],
    redirect: indexSj0gN9ToG9Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSj0gN9ToG9Meta?.name ?? "articles___de",
    path: indexSj0gN9ToG9Meta?.path ?? "/de/artikel",
    url: "/de/artikel",
    meta: indexSj0gN9ToG9Meta || {},
    alias: indexSj0gN9ToG9Meta?.alias || [],
    redirect: indexSj0gN9ToG9Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSj0gN9ToG9Meta?.name ?? "articles___uk",
    path: indexSj0gN9ToG9Meta?.path ?? "/uk/articles",
    url: "/uk/articles",
    meta: indexSj0gN9ToG9Meta || {},
    alias: indexSj0gN9ToG9Meta?.alias || [],
    redirect: indexSj0gN9ToG9Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSj0gN9ToG9Meta?.name ?? "articles___us",
    path: indexSj0gN9ToG9Meta?.path ?? "/us/articles",
    url: "/us/articles",
    meta: indexSj0gN9ToG9Meta || {},
    alias: indexSj0gN9ToG9Meta?.alias || [],
    redirect: indexSj0gN9ToG9Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSj0gN9ToG9Meta?.name ?? "articles___fi",
    path: indexSj0gN9ToG9Meta?.path ?? "/fi/artikkeleita",
    url: "/fi/artikkeleita",
    meta: indexSj0gN9ToG9Meta || {},
    alias: indexSj0gN9ToG9Meta?.alias || [],
    redirect: indexSj0gN9ToG9Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSj0gN9ToG9Meta?.name ?? "articles___no",
    path: indexSj0gN9ToG9Meta?.path ?? "/no/artikler",
    url: "/no/artikler",
    meta: indexSj0gN9ToG9Meta || {},
    alias: indexSj0gN9ToG9Meta?.alias || [],
    redirect: indexSj0gN9ToG9Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: indexSj0gN9ToG9Meta?.name ?? "articles___sv",
    path: indexSj0gN9ToG9Meta?.path ?? "/sv/artiklar",
    url: "/sv/artiklar",
    meta: indexSj0gN9ToG9Meta || {},
    alias: indexSj0gN9ToG9Meta?.alias || [],
    redirect: indexSj0gN9ToG9Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/articles/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vcVBWlzeXiMeta?.name ?? "city-slug___dk",
    path: _91_91slug_93_93vcVBWlzeXiMeta?.path ?? "/dk/by/:slug",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    alias: _91_91slug_93_93vcVBWlzeXiMeta?.alias || [],
    redirect: _91_91slug_93_93vcVBWlzeXiMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vcVBWlzeXiMeta?.name ?? "city-slug___de",
    path: _91_91slug_93_93vcVBWlzeXiMeta?.path ?? "/de/stadt/:slug",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    alias: _91_91slug_93_93vcVBWlzeXiMeta?.alias || [],
    redirect: _91_91slug_93_93vcVBWlzeXiMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vcVBWlzeXiMeta?.name ?? "city-slug___uk",
    path: _91_91slug_93_93vcVBWlzeXiMeta?.path ?? "/uk/city/:slug",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    alias: _91_91slug_93_93vcVBWlzeXiMeta?.alias || [],
    redirect: _91_91slug_93_93vcVBWlzeXiMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vcVBWlzeXiMeta?.name ?? "city-slug___us",
    path: _91_91slug_93_93vcVBWlzeXiMeta?.path ?? "/us/city/:slug",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    alias: _91_91slug_93_93vcVBWlzeXiMeta?.alias || [],
    redirect: _91_91slug_93_93vcVBWlzeXiMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vcVBWlzeXiMeta?.name ?? "city-slug___fi",
    path: _91_91slug_93_93vcVBWlzeXiMeta?.path ?? "/fi/kaupunki/:slug",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    alias: _91_91slug_93_93vcVBWlzeXiMeta?.alias || [],
    redirect: _91_91slug_93_93vcVBWlzeXiMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vcVBWlzeXiMeta?.name ?? "city-slug___no",
    path: _91_91slug_93_93vcVBWlzeXiMeta?.path ?? "/no/by/:slug",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    alias: _91_91slug_93_93vcVBWlzeXiMeta?.alias || [],
    redirect: _91_91slug_93_93vcVBWlzeXiMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93vcVBWlzeXiMeta?.name ?? "city-slug___sv",
    path: _91_91slug_93_93vcVBWlzeXiMeta?.path ?? "/sv/stad/:slug",
    meta: _91_91slug_93_93vcVBWlzeXiMeta || {},
    alias: _91_91slug_93_93vcVBWlzeXiMeta?.alias || [],
    redirect: _91_91slug_93_93vcVBWlzeXiMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/city/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexCKG1JbZsRBMeta?.name ?? "cookies___dk",
    path: indexCKG1JbZsRBMeta?.path ?? "/dk/cookies",
    url: "/dk/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    alias: indexCKG1JbZsRBMeta?.alias || [],
    redirect: indexCKG1JbZsRBMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKG1JbZsRBMeta?.name ?? "cookies___de",
    path: indexCKG1JbZsRBMeta?.path ?? "/de/cookies",
    url: "/de/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    alias: indexCKG1JbZsRBMeta?.alias || [],
    redirect: indexCKG1JbZsRBMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKG1JbZsRBMeta?.name ?? "cookies___uk",
    path: indexCKG1JbZsRBMeta?.path ?? "/uk/cookies",
    url: "/uk/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    alias: indexCKG1JbZsRBMeta?.alias || [],
    redirect: indexCKG1JbZsRBMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKG1JbZsRBMeta?.name ?? "cookies___us",
    path: indexCKG1JbZsRBMeta?.path ?? "/us/cookies",
    url: "/us/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    alias: indexCKG1JbZsRBMeta?.alias || [],
    redirect: indexCKG1JbZsRBMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKG1JbZsRBMeta?.name ?? "cookies___fi",
    path: indexCKG1JbZsRBMeta?.path ?? "/fi/cookies",
    url: "/fi/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    alias: indexCKG1JbZsRBMeta?.alias || [],
    redirect: indexCKG1JbZsRBMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKG1JbZsRBMeta?.name ?? "cookies___no",
    path: indexCKG1JbZsRBMeta?.path ?? "/no/cookies",
    url: "/no/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    alias: indexCKG1JbZsRBMeta?.alias || [],
    redirect: indexCKG1JbZsRBMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: indexCKG1JbZsRBMeta?.name ?? "cookies___sv",
    path: indexCKG1JbZsRBMeta?.path ?? "/sv/cookies",
    url: "/sv/cookies",
    meta: indexCKG1JbZsRBMeta || {},
    alias: indexCKG1JbZsRBMeta?.alias || [],
    redirect: indexCKG1JbZsRBMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/cookies/index.vue").then(m => m.default || m)
  },
  {
    name: country_45selectRUZ8OV9dL5Meta?.name ?? "country-select",
    path: country_45selectRUZ8OV9dL5Meta?.path ?? "/country-select",
    url: "/country-select",
    meta: country_45selectRUZ8OV9dL5Meta || {},
    alias: country_45selectRUZ8OV9dL5Meta?.alias || [],
    redirect: country_45selectRUZ8OV9dL5Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/country-select.vue").then(m => m.default || m)
  },
  {
    name: indexQmeS472sJIMeta?.name ?? "index___sv",
    path: indexQmeS472sJIMeta?.path ?? "/sv",
    url: "/sv",
    meta: indexQmeS472sJIMeta || {},
    alias: indexQmeS472sJIMeta?.alias || [],
    redirect: indexQmeS472sJIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmeS472sJIMeta?.name ?? "index___uk",
    path: indexQmeS472sJIMeta?.path ?? "/uk",
    url: "/uk",
    meta: indexQmeS472sJIMeta || {},
    alias: indexQmeS472sJIMeta?.alias || [],
    redirect: indexQmeS472sJIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmeS472sJIMeta?.name ?? "index___dk",
    path: indexQmeS472sJIMeta?.path ?? "/dk",
    url: "/dk",
    meta: indexQmeS472sJIMeta || {},
    alias: indexQmeS472sJIMeta?.alias || [],
    redirect: indexQmeS472sJIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmeS472sJIMeta?.name ?? "index___de",
    path: indexQmeS472sJIMeta?.path ?? "/de",
    url: "/de",
    meta: indexQmeS472sJIMeta || {},
    alias: indexQmeS472sJIMeta?.alias || [],
    redirect: indexQmeS472sJIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmeS472sJIMeta?.name ?? "index___us",
    path: indexQmeS472sJIMeta?.path ?? "/us",
    url: "/us",
    meta: indexQmeS472sJIMeta || {},
    alias: indexQmeS472sJIMeta?.alias || [],
    redirect: indexQmeS472sJIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmeS472sJIMeta?.name ?? "index___fi",
    path: indexQmeS472sJIMeta?.path ?? "/fi",
    url: "/fi",
    meta: indexQmeS472sJIMeta || {},
    alias: indexQmeS472sJIMeta?.alias || [],
    redirect: indexQmeS472sJIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmeS472sJIMeta?.name ?? "index___no",
    path: indexQmeS472sJIMeta?.path ?? "/no",
    url: "/no",
    meta: indexQmeS472sJIMeta || {},
    alias: indexQmeS472sJIMeta?.alias || [],
    redirect: indexQmeS472sJIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93zxN0AQPn0qMeta?.name ?? "insurance-slug___dk",
    path: _91_91slug_93_93zxN0AQPn0qMeta?.path ?? "/dk/forsikring/:slug",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    alias: _91_91slug_93_93zxN0AQPn0qMeta?.alias || [],
    redirect: _91_91slug_93_93zxN0AQPn0qMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93zxN0AQPn0qMeta?.name ?? "insurance-slug___de",
    path: _91_91slug_93_93zxN0AQPn0qMeta?.path ?? "/de/versicherung/:slug",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    alias: _91_91slug_93_93zxN0AQPn0qMeta?.alias || [],
    redirect: _91_91slug_93_93zxN0AQPn0qMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93zxN0AQPn0qMeta?.name ?? "insurance-slug___uk",
    path: _91_91slug_93_93zxN0AQPn0qMeta?.path ?? "/uk/insurance/:slug",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    alias: _91_91slug_93_93zxN0AQPn0qMeta?.alias || [],
    redirect: _91_91slug_93_93zxN0AQPn0qMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93zxN0AQPn0qMeta?.name ?? "insurance-slug___us",
    path: _91_91slug_93_93zxN0AQPn0qMeta?.path ?? "/us/insurance/:slug",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    alias: _91_91slug_93_93zxN0AQPn0qMeta?.alias || [],
    redirect: _91_91slug_93_93zxN0AQPn0qMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93zxN0AQPn0qMeta?.name ?? "insurance-slug___fi",
    path: _91_91slug_93_93zxN0AQPn0qMeta?.path ?? "/fi/vakuutus/:slug",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    alias: _91_91slug_93_93zxN0AQPn0qMeta?.alias || [],
    redirect: _91_91slug_93_93zxN0AQPn0qMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93zxN0AQPn0qMeta?.name ?? "insurance-slug___no",
    path: _91_91slug_93_93zxN0AQPn0qMeta?.path ?? "/no/forsikring/:slug",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    alias: _91_91slug_93_93zxN0AQPn0qMeta?.alias || [],
    redirect: _91_91slug_93_93zxN0AQPn0qMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93zxN0AQPn0qMeta?.name ?? "insurance-slug___sv",
    path: _91_91slug_93_93zxN0AQPn0qMeta?.path ?? "/sv/forsakring/:slug",
    meta: _91_91slug_93_93zxN0AQPn0qMeta || {},
    alias: _91_91slug_93_93zxN0AQPn0qMeta?.alias || [],
    redirect: _91_91slug_93_93zxN0AQPn0qMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexrGGK5c7L15Meta?.name ?? "insurance___dk",
    path: indexrGGK5c7L15Meta?.path ?? "/dk/forsikring",
    url: "/dk/forsikring",
    meta: indexrGGK5c7L15Meta || {},
    alias: indexrGGK5c7L15Meta?.alias || [],
    redirect: indexrGGK5c7L15Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexrGGK5c7L15Meta?.name ?? "insurance___de",
    path: indexrGGK5c7L15Meta?.path ?? "/de/versicherung",
    url: "/de/versicherung",
    meta: indexrGGK5c7L15Meta || {},
    alias: indexrGGK5c7L15Meta?.alias || [],
    redirect: indexrGGK5c7L15Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexrGGK5c7L15Meta?.name ?? "insurance___uk",
    path: indexrGGK5c7L15Meta?.path ?? "/uk/insurance",
    url: "/uk/insurance",
    meta: indexrGGK5c7L15Meta || {},
    alias: indexrGGK5c7L15Meta?.alias || [],
    redirect: indexrGGK5c7L15Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexrGGK5c7L15Meta?.name ?? "insurance___us",
    path: indexrGGK5c7L15Meta?.path ?? "/us/insurance",
    url: "/us/insurance",
    meta: indexrGGK5c7L15Meta || {},
    alias: indexrGGK5c7L15Meta?.alias || [],
    redirect: indexrGGK5c7L15Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexrGGK5c7L15Meta?.name ?? "insurance___fi",
    path: indexrGGK5c7L15Meta?.path ?? "/fi/vakuutus",
    url: "/fi/vakuutus",
    meta: indexrGGK5c7L15Meta || {},
    alias: indexrGGK5c7L15Meta?.alias || [],
    redirect: indexrGGK5c7L15Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexrGGK5c7L15Meta?.name ?? "insurance___no",
    path: indexrGGK5c7L15Meta?.path ?? "/no/forsikring",
    url: "/no/forsikring",
    meta: indexrGGK5c7L15Meta || {},
    alias: indexrGGK5c7L15Meta?.alias || [],
    redirect: indexrGGK5c7L15Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: indexrGGK5c7L15Meta?.name ?? "insurance___sv",
    path: indexrGGK5c7L15Meta?.path ?? "/sv/forsakring",
    url: "/sv/forsakring",
    meta: indexrGGK5c7L15Meta || {},
    alias: indexrGGK5c7L15Meta?.alias || [],
    redirect: indexrGGK5c7L15Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/insurance/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93Wki3kTUIcEMeta?.name ?? "jobs-slug___dk",
    path: _91_91slug_93_93Wki3kTUIcEMeta?.path ?? "/dk/jobs/:slug",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    alias: _91_91slug_93_93Wki3kTUIcEMeta?.alias || [],
    redirect: _91_91slug_93_93Wki3kTUIcEMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93Wki3kTUIcEMeta?.name ?? "jobs-slug___de",
    path: _91_91slug_93_93Wki3kTUIcEMeta?.path ?? "/de/jobs/:slug",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    alias: _91_91slug_93_93Wki3kTUIcEMeta?.alias || [],
    redirect: _91_91slug_93_93Wki3kTUIcEMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93Wki3kTUIcEMeta?.name ?? "jobs-slug___uk",
    path: _91_91slug_93_93Wki3kTUIcEMeta?.path ?? "/uk/jobs/:slug",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    alias: _91_91slug_93_93Wki3kTUIcEMeta?.alias || [],
    redirect: _91_91slug_93_93Wki3kTUIcEMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93Wki3kTUIcEMeta?.name ?? "jobs-slug___us",
    path: _91_91slug_93_93Wki3kTUIcEMeta?.path ?? "/us/jobs/:slug",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    alias: _91_91slug_93_93Wki3kTUIcEMeta?.alias || [],
    redirect: _91_91slug_93_93Wki3kTUIcEMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93Wki3kTUIcEMeta?.name ?? "jobs-slug___fi",
    path: _91_91slug_93_93Wki3kTUIcEMeta?.path ?? "/fi/jobs/:slug",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    alias: _91_91slug_93_93Wki3kTUIcEMeta?.alias || [],
    redirect: _91_91slug_93_93Wki3kTUIcEMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93Wki3kTUIcEMeta?.name ?? "jobs-slug___no",
    path: _91_91slug_93_93Wki3kTUIcEMeta?.path ?? "/no/jobs/:slug",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    alias: _91_91slug_93_93Wki3kTUIcEMeta?.alias || [],
    redirect: _91_91slug_93_93Wki3kTUIcEMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93Wki3kTUIcEMeta?.name ?? "jobs-slug___sv",
    path: _91_91slug_93_93Wki3kTUIcEMeta?.path ?? "/sv/jobs/:slug",
    meta: _91_91slug_93_93Wki3kTUIcEMeta || {},
    alias: _91_91slug_93_93Wki3kTUIcEMeta?.alias || [],
    redirect: _91_91slug_93_93Wki3kTUIcEMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexsI7gVdPwDlMeta?.name ?? "jobs___dk",
    path: indexsI7gVdPwDlMeta?.path ?? "/dk/jobs",
    url: "/dk/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    alias: indexsI7gVdPwDlMeta?.alias || [],
    redirect: indexsI7gVdPwDlMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexsI7gVdPwDlMeta?.name ?? "jobs___de",
    path: indexsI7gVdPwDlMeta?.path ?? "/de/jobs",
    url: "/de/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    alias: indexsI7gVdPwDlMeta?.alias || [],
    redirect: indexsI7gVdPwDlMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexsI7gVdPwDlMeta?.name ?? "jobs___uk",
    path: indexsI7gVdPwDlMeta?.path ?? "/uk/jobs",
    url: "/uk/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    alias: indexsI7gVdPwDlMeta?.alias || [],
    redirect: indexsI7gVdPwDlMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexsI7gVdPwDlMeta?.name ?? "jobs___us",
    path: indexsI7gVdPwDlMeta?.path ?? "/us/jobs",
    url: "/us/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    alias: indexsI7gVdPwDlMeta?.alias || [],
    redirect: indexsI7gVdPwDlMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexsI7gVdPwDlMeta?.name ?? "jobs___fi",
    path: indexsI7gVdPwDlMeta?.path ?? "/fi/jobs",
    url: "/fi/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    alias: indexsI7gVdPwDlMeta?.alias || [],
    redirect: indexsI7gVdPwDlMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexsI7gVdPwDlMeta?.name ?? "jobs___no",
    path: indexsI7gVdPwDlMeta?.path ?? "/no/jobs",
    url: "/no/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    alias: indexsI7gVdPwDlMeta?.alias || [],
    redirect: indexsI7gVdPwDlMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: indexsI7gVdPwDlMeta?.name ?? "jobs___sv",
    path: indexsI7gVdPwDlMeta?.path ?? "/sv/jobs",
    url: "/sv/jobs",
    meta: indexsI7gVdPwDlMeta || {},
    alias: indexsI7gVdPwDlMeta?.alias || [],
    redirect: indexsI7gVdPwDlMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/jobs/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_935ZPROMWTytMeta?.name ?? "partner-slug___dk",
    path: _91_91slug_93_935ZPROMWTytMeta?.path ?? "/dk/partners/:slug",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    alias: _91_91slug_93_935ZPROMWTytMeta?.alias || [],
    redirect: _91_91slug_93_935ZPROMWTytMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_935ZPROMWTytMeta?.name ?? "partner-slug___de",
    path: _91_91slug_93_935ZPROMWTytMeta?.path ?? "/de/versicherungspartnern/:slug",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    alias: _91_91slug_93_935ZPROMWTytMeta?.alias || [],
    redirect: _91_91slug_93_935ZPROMWTytMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_935ZPROMWTytMeta?.name ?? "partner-slug___uk",
    path: _91_91slug_93_935ZPROMWTytMeta?.path ?? "/uk/partners/:slug",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    alias: _91_91slug_93_935ZPROMWTytMeta?.alias || [],
    redirect: _91_91slug_93_935ZPROMWTytMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_935ZPROMWTytMeta?.name ?? "partner-slug___us",
    path: _91_91slug_93_935ZPROMWTytMeta?.path ?? "/us/partners/:slug",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    alias: _91_91slug_93_935ZPROMWTytMeta?.alias || [],
    redirect: _91_91slug_93_935ZPROMWTytMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_935ZPROMWTytMeta?.name ?? "partner-slug___fi",
    path: _91_91slug_93_935ZPROMWTytMeta?.path ?? "/fi/yhteisty%C3%B6kumppanit/:slug",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    alias: _91_91slug_93_935ZPROMWTytMeta?.alias || [],
    redirect: _91_91slug_93_935ZPROMWTytMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_935ZPROMWTytMeta?.name ?? "partner-slug___no",
    path: _91_91slug_93_935ZPROMWTytMeta?.path ?? "/no/partners/:slug",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    alias: _91_91slug_93_935ZPROMWTytMeta?.alias || [],
    redirect: _91_91slug_93_935ZPROMWTytMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_935ZPROMWTytMeta?.name ?? "partner-slug___sv",
    path: _91_91slug_93_935ZPROMWTytMeta?.path ?? "/sv/partners/:slug",
    meta: _91_91slug_93_935ZPROMWTytMeta || {},
    alias: _91_91slug_93_935ZPROMWTytMeta?.alias || [],
    redirect: _91_91slug_93_935ZPROMWTytMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: index1I6ece1RfXMeta?.name ?? "partner___dk",
    path: index1I6ece1RfXMeta?.path ?? "/dk/partners",
    url: "/dk/partners",
    meta: index1I6ece1RfXMeta || {},
    alias: index1I6ece1RfXMeta?.alias || [],
    redirect: index1I6ece1RfXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index1I6ece1RfXMeta?.name ?? "partner___de",
    path: index1I6ece1RfXMeta?.path ?? "/de/versicherungspartnern",
    url: "/de/versicherungspartnern",
    meta: index1I6ece1RfXMeta || {},
    alias: index1I6ece1RfXMeta?.alias || [],
    redirect: index1I6ece1RfXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index1I6ece1RfXMeta?.name ?? "partner___uk",
    path: index1I6ece1RfXMeta?.path ?? "/uk/partners",
    url: "/uk/partners",
    meta: index1I6ece1RfXMeta || {},
    alias: index1I6ece1RfXMeta?.alias || [],
    redirect: index1I6ece1RfXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index1I6ece1RfXMeta?.name ?? "partner___us",
    path: index1I6ece1RfXMeta?.path ?? "/us/partners",
    url: "/us/partners",
    meta: index1I6ece1RfXMeta || {},
    alias: index1I6ece1RfXMeta?.alias || [],
    redirect: index1I6ece1RfXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index1I6ece1RfXMeta?.name ?? "partner___fi",
    path: index1I6ece1RfXMeta?.path ?? "/fi/yhteisty%C3%B6kumppanit",
    url: "/fi/yhteisty%C3%B6kumppanit",
    meta: index1I6ece1RfXMeta || {},
    alias: index1I6ece1RfXMeta?.alias || [],
    redirect: index1I6ece1RfXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index1I6ece1RfXMeta?.name ?? "partner___no",
    path: index1I6ece1RfXMeta?.path ?? "/no/partners",
    url: "/no/partners",
    meta: index1I6ece1RfXMeta || {},
    alias: index1I6ece1RfXMeta?.alias || [],
    redirect: index1I6ece1RfXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: index1I6ece1RfXMeta?.name ?? "partner___sv",
    path: index1I6ece1RfXMeta?.path ?? "/sv/partners",
    url: "/sv/partners",
    meta: index1I6ece1RfXMeta || {},
    alias: index1I6ece1RfXMeta?.alias || [],
    redirect: index1I6ece1RfXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/partner/index.vue").then(m => m.default || m)
  },
  {
    name: indexkw7BFYJcRJMeta?.name ?? "ppcontacts___dk",
    path: indexkw7BFYJcRJMeta?.path ?? "/dk/ppcontacts",
    url: "/dk/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    alias: indexkw7BFYJcRJMeta?.alias || [],
    redirect: indexkw7BFYJcRJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexkw7BFYJcRJMeta?.name ?? "ppcontacts___de",
    path: indexkw7BFYJcRJMeta?.path ?? "/de/ppcontacts",
    url: "/de/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    alias: indexkw7BFYJcRJMeta?.alias || [],
    redirect: indexkw7BFYJcRJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexkw7BFYJcRJMeta?.name ?? "ppcontacts___uk",
    path: indexkw7BFYJcRJMeta?.path ?? "/uk/ppcontacts",
    url: "/uk/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    alias: indexkw7BFYJcRJMeta?.alias || [],
    redirect: indexkw7BFYJcRJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexkw7BFYJcRJMeta?.name ?? "ppcontacts___us",
    path: indexkw7BFYJcRJMeta?.path ?? "/us/ppcontacts",
    url: "/us/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    alias: indexkw7BFYJcRJMeta?.alias || [],
    redirect: indexkw7BFYJcRJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexkw7BFYJcRJMeta?.name ?? "ppcontacts___fi",
    path: indexkw7BFYJcRJMeta?.path ?? "/fi/ppcontacts",
    url: "/fi/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    alias: indexkw7BFYJcRJMeta?.alias || [],
    redirect: indexkw7BFYJcRJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexkw7BFYJcRJMeta?.name ?? "ppcontacts___no",
    path: indexkw7BFYJcRJMeta?.path ?? "/no/ppcontacts",
    url: "/no/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    alias: indexkw7BFYJcRJMeta?.alias || [],
    redirect: indexkw7BFYJcRJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexkw7BFYJcRJMeta?.name ?? "ppcontacts___sv",
    path: indexkw7BFYJcRJMeta?.path ?? "/sv/ppcontacts",
    url: "/sv/ppcontacts",
    meta: indexkw7BFYJcRJMeta || {},
    alias: indexkw7BFYJcRJMeta?.alias || [],
    redirect: indexkw7BFYJcRJMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppcontacts/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFq9PSOw4PMeta?.name ?? "ppja___dk",
    path: indexYFq9PSOw4PMeta?.path ?? "/dk/ppja",
    url: "/dk/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    alias: indexYFq9PSOw4PMeta?.alias || [],
    redirect: indexYFq9PSOw4PMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFq9PSOw4PMeta?.name ?? "ppja___de",
    path: indexYFq9PSOw4PMeta?.path ?? "/de/ppja",
    url: "/de/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    alias: indexYFq9PSOw4PMeta?.alias || [],
    redirect: indexYFq9PSOw4PMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFq9PSOw4PMeta?.name ?? "ppja___uk",
    path: indexYFq9PSOw4PMeta?.path ?? "/uk/ppja",
    url: "/uk/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    alias: indexYFq9PSOw4PMeta?.alias || [],
    redirect: indexYFq9PSOw4PMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFq9PSOw4PMeta?.name ?? "ppja___us",
    path: indexYFq9PSOw4PMeta?.path ?? "/us/ppja",
    url: "/us/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    alias: indexYFq9PSOw4PMeta?.alias || [],
    redirect: indexYFq9PSOw4PMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFq9PSOw4PMeta?.name ?? "ppja___fi",
    path: indexYFq9PSOw4PMeta?.path ?? "/fi/ppja",
    url: "/fi/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    alias: indexYFq9PSOw4PMeta?.alias || [],
    redirect: indexYFq9PSOw4PMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFq9PSOw4PMeta?.name ?? "ppja___no",
    path: indexYFq9PSOw4PMeta?.path ?? "/no/ppja",
    url: "/no/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    alias: indexYFq9PSOw4PMeta?.alias || [],
    redirect: indexYFq9PSOw4PMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: indexYFq9PSOw4PMeta?.name ?? "ppja___sv",
    path: indexYFq9PSOw4PMeta?.path ?? "/sv/ppja",
    url: "/sv/ppja",
    meta: indexYFq9PSOw4PMeta || {},
    alias: indexYFq9PSOw4PMeta?.alias || [],
    redirect: indexYFq9PSOw4PMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/ppja/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMkMgv9Sb8Meta?.name ?? "press___dk",
    path: indexYMkMgv9Sb8Meta?.path ?? "/dk/press",
    url: "/dk/press",
    meta: indexYMkMgv9Sb8Meta || {},
    alias: indexYMkMgv9Sb8Meta?.alias || [],
    redirect: indexYMkMgv9Sb8Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMkMgv9Sb8Meta?.name ?? "press___de",
    path: indexYMkMgv9Sb8Meta?.path ?? "/de/press",
    url: "/de/press",
    meta: indexYMkMgv9Sb8Meta || {},
    alias: indexYMkMgv9Sb8Meta?.alias || [],
    redirect: indexYMkMgv9Sb8Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMkMgv9Sb8Meta?.name ?? "press___uk",
    path: indexYMkMgv9Sb8Meta?.path ?? "/uk/press",
    url: "/uk/press",
    meta: indexYMkMgv9Sb8Meta || {},
    alias: indexYMkMgv9Sb8Meta?.alias || [],
    redirect: indexYMkMgv9Sb8Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMkMgv9Sb8Meta?.name ?? "press___us",
    path: indexYMkMgv9Sb8Meta?.path ?? "/us/press",
    url: "/us/press",
    meta: indexYMkMgv9Sb8Meta || {},
    alias: indexYMkMgv9Sb8Meta?.alias || [],
    redirect: indexYMkMgv9Sb8Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMkMgv9Sb8Meta?.name ?? "press___fi",
    path: indexYMkMgv9Sb8Meta?.path ?? "/fi/press",
    url: "/fi/press",
    meta: indexYMkMgv9Sb8Meta || {},
    alias: indexYMkMgv9Sb8Meta?.alias || [],
    redirect: indexYMkMgv9Sb8Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMkMgv9Sb8Meta?.name ?? "press___no",
    path: indexYMkMgv9Sb8Meta?.path ?? "/no/press",
    url: "/no/press",
    meta: indexYMkMgv9Sb8Meta || {},
    alias: indexYMkMgv9Sb8Meta?.alias || [],
    redirect: indexYMkMgv9Sb8Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: indexYMkMgv9Sb8Meta?.name ?? "press___sv",
    path: indexYMkMgv9Sb8Meta?.path ?? "/sv/press",
    url: "/sv/press",
    meta: indexYMkMgv9Sb8Meta || {},
    alias: indexYMkMgv9Sb8Meta?.alias || [],
    redirect: indexYMkMgv9Sb8Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93EAk2oHQDjIMeta?.name ?? "press-pr-slug___dk",
    path: _91_91slug_93_93EAk2oHQDjIMeta?.path ?? "/dk/press/pr/:slug",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    alias: _91_91slug_93_93EAk2oHQDjIMeta?.alias || [],
    redirect: _91_91slug_93_93EAk2oHQDjIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93EAk2oHQDjIMeta?.name ?? "press-pr-slug___de",
    path: _91_91slug_93_93EAk2oHQDjIMeta?.path ?? "/de/press/pr/:slug",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    alias: _91_91slug_93_93EAk2oHQDjIMeta?.alias || [],
    redirect: _91_91slug_93_93EAk2oHQDjIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93EAk2oHQDjIMeta?.name ?? "press-pr-slug___uk",
    path: _91_91slug_93_93EAk2oHQDjIMeta?.path ?? "/uk/press/pr/:slug",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    alias: _91_91slug_93_93EAk2oHQDjIMeta?.alias || [],
    redirect: _91_91slug_93_93EAk2oHQDjIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93EAk2oHQDjIMeta?.name ?? "press-pr-slug___us",
    path: _91_91slug_93_93EAk2oHQDjIMeta?.path ?? "/us/press/pr/:slug",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    alias: _91_91slug_93_93EAk2oHQDjIMeta?.alias || [],
    redirect: _91_91slug_93_93EAk2oHQDjIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93EAk2oHQDjIMeta?.name ?? "press-pr-slug___fi",
    path: _91_91slug_93_93EAk2oHQDjIMeta?.path ?? "/fi/press/pr/:slug",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    alias: _91_91slug_93_93EAk2oHQDjIMeta?.alias || [],
    redirect: _91_91slug_93_93EAk2oHQDjIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93EAk2oHQDjIMeta?.name ?? "press-pr-slug___no",
    path: _91_91slug_93_93EAk2oHQDjIMeta?.path ?? "/no/press/pr/:slug",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    alias: _91_91slug_93_93EAk2oHQDjIMeta?.alias || [],
    redirect: _91_91slug_93_93EAk2oHQDjIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93EAk2oHQDjIMeta?.name ?? "press-pr-slug___sv",
    path: _91_91slug_93_93EAk2oHQDjIMeta?.path ?? "/sv/press/pr/:slug",
    meta: _91_91slug_93_93EAk2oHQDjIMeta || {},
    alias: _91_91slug_93_93EAk2oHQDjIMeta?.alias || [],
    redirect: _91_91slug_93_93EAk2oHQDjIMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: pricOJhDGaSfMeta?.name ?? "press-pr-pr___dk",
    path: pricOJhDGaSfMeta?.path ?? "/dk/press/pr",
    url: "/dk/press/pr",
    meta: pricOJhDGaSfMeta || {},
    alias: pricOJhDGaSfMeta?.alias || [],
    redirect: pricOJhDGaSfMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: pricOJhDGaSfMeta?.name ?? "press-pr-pr___de",
    path: pricOJhDGaSfMeta?.path ?? "/de/press/pr",
    url: "/de/press/pr",
    meta: pricOJhDGaSfMeta || {},
    alias: pricOJhDGaSfMeta?.alias || [],
    redirect: pricOJhDGaSfMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: pricOJhDGaSfMeta?.name ?? "press-pr-pr___uk",
    path: pricOJhDGaSfMeta?.path ?? "/uk/press/pr",
    url: "/uk/press/pr",
    meta: pricOJhDGaSfMeta || {},
    alias: pricOJhDGaSfMeta?.alias || [],
    redirect: pricOJhDGaSfMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: pricOJhDGaSfMeta?.name ?? "press-pr-pr___us",
    path: pricOJhDGaSfMeta?.path ?? "/us/press/pr",
    url: "/us/press/pr",
    meta: pricOJhDGaSfMeta || {},
    alias: pricOJhDGaSfMeta?.alias || [],
    redirect: pricOJhDGaSfMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: pricOJhDGaSfMeta?.name ?? "press-pr-pr___fi",
    path: pricOJhDGaSfMeta?.path ?? "/fi/press/pr",
    url: "/fi/press/pr",
    meta: pricOJhDGaSfMeta || {},
    alias: pricOJhDGaSfMeta?.alias || [],
    redirect: pricOJhDGaSfMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: pricOJhDGaSfMeta?.name ?? "press-pr-pr___no",
    path: pricOJhDGaSfMeta?.path ?? "/no/press/pr",
    url: "/no/press/pr",
    meta: pricOJhDGaSfMeta || {},
    alias: pricOJhDGaSfMeta?.alias || [],
    redirect: pricOJhDGaSfMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: pricOJhDGaSfMeta?.name ?? "press-pr-pr___sv",
    path: pricOJhDGaSfMeta?.path ?? "/sv/press/pr",
    url: "/sv/press/pr",
    meta: pricOJhDGaSfMeta || {},
    alias: pricOJhDGaSfMeta?.alias || [],
    redirect: pricOJhDGaSfMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/press/pr/pr.vue").then(m => m.default || m)
  },
  {
    name: indexHKSv4Uf9U3Meta?.name ?? "privacy-policy___dk",
    path: indexHKSv4Uf9U3Meta?.path ?? "/dk/privatlivspolitik",
    url: "/dk/privatlivspolitik",
    meta: indexHKSv4Uf9U3Meta || {},
    alias: indexHKSv4Uf9U3Meta?.alias || [],
    redirect: indexHKSv4Uf9U3Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexHKSv4Uf9U3Meta?.name ?? "privacy-policy___de",
    path: indexHKSv4Uf9U3Meta?.path ?? "/de/datenschutz-bestimmungen",
    url: "/de/datenschutz-bestimmungen",
    meta: indexHKSv4Uf9U3Meta || {},
    alias: indexHKSv4Uf9U3Meta?.alias || [],
    redirect: indexHKSv4Uf9U3Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexHKSv4Uf9U3Meta?.name ?? "privacy-policy___uk",
    path: indexHKSv4Uf9U3Meta?.path ?? "/uk/privacy-policy",
    url: "/uk/privacy-policy",
    meta: indexHKSv4Uf9U3Meta || {},
    alias: indexHKSv4Uf9U3Meta?.alias || [],
    redirect: indexHKSv4Uf9U3Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexHKSv4Uf9U3Meta?.name ?? "privacy-policy___us",
    path: indexHKSv4Uf9U3Meta?.path ?? "/us/privacy-policy",
    url: "/us/privacy-policy",
    meta: indexHKSv4Uf9U3Meta || {},
    alias: indexHKSv4Uf9U3Meta?.alias || [],
    redirect: indexHKSv4Uf9U3Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexHKSv4Uf9U3Meta?.name ?? "privacy-policy___fi",
    path: indexHKSv4Uf9U3Meta?.path ?? "/fi/tietosuojakaytanto",
    url: "/fi/tietosuojakaytanto",
    meta: indexHKSv4Uf9U3Meta || {},
    alias: indexHKSv4Uf9U3Meta?.alias || [],
    redirect: indexHKSv4Uf9U3Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexHKSv4Uf9U3Meta?.name ?? "privacy-policy___no",
    path: indexHKSv4Uf9U3Meta?.path ?? "/no/personvernpolicy",
    url: "/no/personvernpolicy",
    meta: indexHKSv4Uf9U3Meta || {},
    alias: indexHKSv4Uf9U3Meta?.alias || [],
    redirect: indexHKSv4Uf9U3Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexHKSv4Uf9U3Meta?.name ?? "privacy-policy___sv",
    path: indexHKSv4Uf9U3Meta?.path ?? "/sv/integritetspolicy",
    url: "/sv/integritetspolicy",
    meta: indexHKSv4Uf9U3Meta || {},
    alias: indexHKSv4Uf9U3Meta?.alias || [],
    redirect: indexHKSv4Uf9U3Meta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/privacy-policy/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93kR4dFt4x9xMeta?.name ?? "questions-id-slug___dk",
    path: _91_91slug_93_93kR4dFt4x9xMeta?.path ?? "/dk/sporgsmal/:id/:slug",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    alias: _91_91slug_93_93kR4dFt4x9xMeta?.alias || [],
    redirect: _91_91slug_93_93kR4dFt4x9xMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93kR4dFt4x9xMeta?.name ?? "questions-id-slug___de",
    path: _91_91slug_93_93kR4dFt4x9xMeta?.path ?? "/de/fragen/:id/:slug",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    alias: _91_91slug_93_93kR4dFt4x9xMeta?.alias || [],
    redirect: _91_91slug_93_93kR4dFt4x9xMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93kR4dFt4x9xMeta?.name ?? "questions-id-slug___uk",
    path: _91_91slug_93_93kR4dFt4x9xMeta?.path ?? "/uk/questions/:id/:slug",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    alias: _91_91slug_93_93kR4dFt4x9xMeta?.alias || [],
    redirect: _91_91slug_93_93kR4dFt4x9xMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93kR4dFt4x9xMeta?.name ?? "questions-id-slug___fi",
    path: _91_91slug_93_93kR4dFt4x9xMeta?.path ?? "/fi/kysymykset/:id/:slug",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    alias: _91_91slug_93_93kR4dFt4x9xMeta?.alias || [],
    redirect: _91_91slug_93_93kR4dFt4x9xMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93kR4dFt4x9xMeta?.name ?? "questions-id-slug___no",
    path: _91_91slug_93_93kR4dFt4x9xMeta?.path ?? "/no/sporsmal/:id/:slug",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    alias: _91_91slug_93_93kR4dFt4x9xMeta?.alias || [],
    redirect: _91_91slug_93_93kR4dFt4x9xMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: _91_91slug_93_93kR4dFt4x9xMeta?.name ?? "questions-id-slug___sv",
    path: _91_91slug_93_93kR4dFt4x9xMeta?.path ?? "/sv/fragor/:id/:slug",
    meta: _91_91slug_93_93kR4dFt4x9xMeta || {},
    alias: _91_91slug_93_93kR4dFt4x9xMeta?.alias || [],
    redirect: _91_91slug_93_93kR4dFt4x9xMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/[[id]]/[[slug]].vue").then(m => m.default || m)
  },
  {
    name: indexCbu0Vwv5dAMeta?.name ?? "questions___dk",
    path: indexCbu0Vwv5dAMeta?.path ?? "/dk/sporgsmal",
    url: "/dk/sporgsmal",
    meta: indexCbu0Vwv5dAMeta || {},
    alias: indexCbu0Vwv5dAMeta?.alias || [],
    redirect: indexCbu0Vwv5dAMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: indexCbu0Vwv5dAMeta?.name ?? "questions___de",
    path: indexCbu0Vwv5dAMeta?.path ?? "/de/fragen",
    url: "/de/fragen",
    meta: indexCbu0Vwv5dAMeta || {},
    alias: indexCbu0Vwv5dAMeta?.alias || [],
    redirect: indexCbu0Vwv5dAMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: indexCbu0Vwv5dAMeta?.name ?? "questions___uk",
    path: indexCbu0Vwv5dAMeta?.path ?? "/uk/questions",
    url: "/uk/questions",
    meta: indexCbu0Vwv5dAMeta || {},
    alias: indexCbu0Vwv5dAMeta?.alias || [],
    redirect: indexCbu0Vwv5dAMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: indexCbu0Vwv5dAMeta?.name ?? "questions___fi",
    path: indexCbu0Vwv5dAMeta?.path ?? "/fi/kysymykset",
    url: "/fi/kysymykset",
    meta: indexCbu0Vwv5dAMeta || {},
    alias: indexCbu0Vwv5dAMeta?.alias || [],
    redirect: indexCbu0Vwv5dAMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: indexCbu0Vwv5dAMeta?.name ?? "questions___no",
    path: indexCbu0Vwv5dAMeta?.path ?? "/no/sporsmal",
    url: "/no/sporsmal",
    meta: indexCbu0Vwv5dAMeta || {},
    alias: indexCbu0Vwv5dAMeta?.alias || [],
    redirect: indexCbu0Vwv5dAMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: indexCbu0Vwv5dAMeta?.name ?? "questions___sv",
    path: indexCbu0Vwv5dAMeta?.path ?? "/sv/fragor",
    url: "/sv/fragor",
    meta: indexCbu0Vwv5dAMeta || {},
    alias: indexCbu0Vwv5dAMeta?.alias || [],
    redirect: indexCbu0Vwv5dAMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/questions/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mSs9Smo4WhMeta?.name ?? "shop-slug___sv",
    path: _91_46_46_46slug_93mSs9Smo4WhMeta?.path ?? "/sv/butik/:slug(.*)*",
    meta: _91_46_46_46slug_93mSs9Smo4WhMeta || {},
    alias: _91_46_46_46slug_93mSs9Smo4WhMeta?.alias || [],
    redirect: _91_46_46_46slug_93mSs9Smo4WhMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93mSs9Smo4WhMeta?.name ?? "shop-slug___en",
    path: _91_46_46_46slug_93mSs9Smo4WhMeta?.path ?? "/en/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93mSs9Smo4WhMeta || {},
    alias: _91_46_46_46slug_93mSs9Smo4WhMeta?.alias || [],
    redirect: _91_46_46_46slug_93mSs9Smo4WhMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexXFHzzTqn00Meta?.name ?? "shop-advent-calendar___sv",
    path: indexXFHzzTqn00Meta?.path ?? "/sv/butik/djurkalender",
    url: "/sv/butik/djurkalender",
    meta: indexXFHzzTqn00Meta || {},
    alias: indexXFHzzTqn00Meta?.alias || [],
    redirect: indexXFHzzTqn00Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/advent-calendar/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93F0vGeFjjkGMeta?.name ?? "shop-brands-slug___sv",
    path: _91_46_46_46slug_93F0vGeFjjkGMeta?.path ?? "/sv/butik/varumarken/:slug",
    meta: _91_46_46_46slug_93F0vGeFjjkGMeta || {},
    alias: _91_46_46_46slug_93F0vGeFjjkGMeta?.alias || [],
    redirect: _91_46_46_46slug_93F0vGeFjjkGMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/brands/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93F0vGeFjjkGMeta?.name ?? "shop-brands-slug___en",
    path: _91_46_46_46slug_93F0vGeFjjkGMeta?.path ?? "/en/shop/brands/:slug",
    meta: _91_46_46_46slug_93F0vGeFjjkGMeta || {},
    alias: _91_46_46_46slug_93F0vGeFjjkGMeta?.alias || [],
    redirect: _91_46_46_46slug_93F0vGeFjjkGMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/brands/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexaRZzOQuEw3Meta?.name ?? "shop-brands___sv",
    path: indexaRZzOQuEw3Meta?.path ?? "/sv/butik/varumarken",
    url: "/sv/butik/varumarken",
    meta: indexaRZzOQuEw3Meta || {},
    alias: indexaRZzOQuEw3Meta?.alias || [],
    redirect: indexaRZzOQuEw3Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/brands/index.vue").then(m => m.default || m)
  },
  {
    name: indexaRZzOQuEw3Meta?.name ?? "shop-brands___en",
    path: indexaRZzOQuEw3Meta?.path ?? "/en/shop/brands",
    url: "/en/shop/brands",
    meta: indexaRZzOQuEw3Meta || {},
    alias: indexaRZzOQuEw3Meta?.alias || [],
    redirect: indexaRZzOQuEw3Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/brands/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_930OZamWOekBMeta?.name ?? "shop-campaigns-slug___sv",
    path: _91_46_46_46slug_930OZamWOekBMeta?.path ?? "/sv/butik/kampanjer/:slug",
    meta: _91_46_46_46slug_930OZamWOekBMeta || {},
    alias: _91_46_46_46slug_930OZamWOekBMeta?.alias || [],
    redirect: _91_46_46_46slug_930OZamWOekBMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_930OZamWOekBMeta?.name ?? "shop-campaigns-slug___en",
    path: _91_46_46_46slug_930OZamWOekBMeta?.path ?? "/en/shop/campaigns/:slug",
    meta: _91_46_46_46slug_930OZamWOekBMeta || {},
    alias: _91_46_46_46slug_930OZamWOekBMeta?.alias || [],
    redirect: _91_46_46_46slug_930OZamWOekBMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/campaigns/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91animalType_93JIT39z5rPOMeta?.name ?? "shop-campaigns-black-week-animalType___sv",
    path: _91animalType_93JIT39z5rPOMeta?.path ?? "/sv/butik/kampanjer/black-week/:animalType",
    meta: _91animalType_93JIT39z5rPOMeta || {},
    alias: _91animalType_93JIT39z5rPOMeta?.alias || [],
    redirect: _91animalType_93JIT39z5rPOMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/campaigns/black-week/[animalType].vue").then(m => m.default || m)
  },
  {
    name: indexB5pyBKNhFeMeta?.name ?? "shop-campaigns-black-week___sv",
    path: indexB5pyBKNhFeMeta?.path ?? "/sv/butik/kampanjer/black-week",
    url: "/sv/butik/kampanjer/black-week",
    meta: indexB5pyBKNhFeMeta || {},
    alias: indexB5pyBKNhFeMeta?.alias || [],
    redirect: indexB5pyBKNhFeMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/campaigns/black-week/index.vue").then(m => m.default || m)
  },
  {
    name: indexs9y6zoe1NiMeta?.name ?? "shop-campaigns___sv",
    path: indexs9y6zoe1NiMeta?.path ?? "/sv/butik/kampanjer",
    url: "/sv/butik/kampanjer",
    meta: indexs9y6zoe1NiMeta || {},
    alias: indexs9y6zoe1NiMeta?.alias || [],
    redirect: indexs9y6zoe1NiMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: indexs9y6zoe1NiMeta?.name ?? "shop-campaigns___en",
    path: indexs9y6zoe1NiMeta?.path ?? "/en/shop/campaigns",
    url: "/en/shop/campaigns",
    meta: indexs9y6zoe1NiMeta || {},
    alias: indexs9y6zoe1NiMeta?.alias || [],
    redirect: indexs9y6zoe1NiMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/campaigns/index.vue").then(m => m.default || m)
  },
  {
    name: checkoutXiXOBYjJz7Meta?.name ?? "shop-checkout___sv",
    path: checkoutXiXOBYjJz7Meta?.path ?? "/sv/butik/kassa",
    url: "/sv/butik/kassa",
    meta: checkoutXiXOBYjJz7Meta || {},
    alias: checkoutXiXOBYjJz7Meta?.alias || [],
    redirect: checkoutXiXOBYjJz7Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/checkout.vue").then(m => m.default || m)
  },
  {
    name: checkoutXiXOBYjJz7Meta?.name ?? "shop-checkout___en",
    path: checkoutXiXOBYjJz7Meta?.path ?? "/en/shop/checkout",
    url: "/en/shop/checkout",
    meta: checkoutXiXOBYjJz7Meta || {},
    alias: checkoutXiXOBYjJz7Meta?.alias || [],
    redirect: checkoutXiXOBYjJz7Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/checkout.vue").then(m => m.default || m)
  },
  {
    name: dashboardbeOZBhndB4Meta?.name ?? "shop-dashboard___sv",
    path: dashboardbeOZBhndB4Meta?.path ?? "/sv/butik/mina-sidor",
    children: [
  {
    name: indexSF2jPFF0YxMeta?.name ?? "shop-dashboard-account-settings___sv",
    path: indexSF2jPFF0YxMeta?.path ?? "/sv/butik/mina-sidor/kontoinstallningar",
    url: "/sv/butik/mina-sidor//sv/butik/mina-sidor/kontoinstallningar",
    meta: indexSF2jPFF0YxMeta || {},
    alias: indexSF2jPFF0YxMeta?.alias || [],
    redirect: indexSF2jPFF0YxMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/account-settings/index.vue").then(m => m.default || m)
  },
  {
    name: personal_45informationK1lrgxJGRMMeta?.name ?? "shop-dashboard-account-settings-personal-information___sv",
    path: personal_45informationK1lrgxJGRMMeta?.path ?? "/sv/butik/mina-sidor/kontoinstallningar/personuppgifter",
    url: "/sv/butik/mina-sidor//sv/butik/mina-sidor/kontoinstallningar/personuppgifter",
    meta: personal_45informationK1lrgxJGRMMeta || {},
    alias: personal_45informationK1lrgxJGRMMeta?.alias || [],
    redirect: personal_45informationK1lrgxJGRMMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/account-settings/personal-information.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ziX0D1pBrVMeta?.name ?? "shop-dashboard-order-history-id___sv",
    path: _91id_93ziX0D1pBrVMeta?.path ?? "/sv/butik/mina-sidor/orderhistorik/:id",
    meta: _91id_93ziX0D1pBrVMeta || {},
    alias: _91id_93ziX0D1pBrVMeta?.alias || [],
    redirect: _91id_93ziX0D1pBrVMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/order-history/[id].vue").then(m => m.default || m)
  },
  {
    name: index8vj6lGFTH8Meta?.name ?? "shop-dashboard-order-history___sv",
    path: index8vj6lGFTH8Meta?.path ?? "/sv/butik/mina-sidor/orderhistorik",
    url: "/sv/butik/mina-sidor//sv/butik/mina-sidor/orderhistorik",
    meta: index8vj6lGFTH8Meta || {},
    alias: index8vj6lGFTH8Meta?.alias || [],
    redirect: index8vj6lGFTH8Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/order-history/index.vue").then(m => m.default || m)
  },
  {
    name: prescriptionsQe26GYsy7GMeta?.name ?? "shop-dashboard-prescriptions___sv",
    path: prescriptionsQe26GYsy7GMeta?.path ?? "/sv/butik/mina-sidor/recept",
    url: "/sv/butik/mina-sidor//sv/butik/mina-sidor/recept",
    meta: prescriptionsQe26GYsy7GMeta || {},
    alias: prescriptionsQe26GYsy7GMeta?.alias || [],
    redirect: prescriptionsQe26GYsy7GMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/prescriptions.vue").then(m => m.default || m)
  },
  {
    name: returnsB7TT9o8xldMeta?.name ?? "shop-dashboard-returns___sv",
    path: returnsB7TT9o8xldMeta?.path ?? "/sv/butik/mina-sidor/returer",
    url: "/sv/butik/mina-sidor//sv/butik/mina-sidor/returer",
    meta: returnsB7TT9o8xldMeta || {},
    alias: returnsB7TT9o8xldMeta?.alias || [],
    redirect: returnsB7TT9o8xldMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/returns.vue").then(m => m.default || m)
  },
  {
    name: _91id_933uJOlwtu77Meta?.name ?? "shop-dashboard-subscriptions-id___sv",
    path: _91id_933uJOlwtu77Meta?.path ?? "/sv/butik/mina-sidor/prenumerationer/:id",
    meta: _91id_933uJOlwtu77Meta || {},
    alias: _91id_933uJOlwtu77Meta?.alias || [],
    redirect: _91id_933uJOlwtu77Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/subscriptions/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzuRYw9xbQDMeta?.name ?? "shop-dashboard-subscriptions___sv",
    path: indexzuRYw9xbQDMeta?.path ?? "/sv/butik/mina-sidor/prenumerationer",
    url: "/sv/butik/mina-sidor//sv/butik/mina-sidor/prenumerationer",
    meta: indexzuRYw9xbQDMeta || {},
    alias: indexzuRYw9xbQDMeta?.alias || [],
    redirect: indexzuRYw9xbQDMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard/subscriptions/index.vue").then(m => m.default || m)
  }
],
    meta: dashboardbeOZBhndB4Meta || {},
    alias: dashboardbeOZBhndB4Meta?.alias || [],
    redirect: dashboardbeOZBhndB4Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/dashboard.vue").then(m => m.default || m)
  },
  {
    name: favoritesbKhaaTBmfxMeta?.name ?? "shop-favorites___sv",
    path: favoritesbKhaaTBmfxMeta?.path ?? "/sv/butik/favorites",
    url: "/sv/butik/favorites",
    meta: favoritesbKhaaTBmfxMeta || {},
    alias: favoritesbKhaaTBmfxMeta?.alias || [],
    redirect: favoritesbKhaaTBmfxMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/favorites.vue").then(m => m.default || m)
  },
  {
    name: food_45consultationonyIXKSevZMeta?.name ?? "shop-food-consultation___sv",
    path: food_45consultationonyIXKSevZMeta?.path ?? "/sv/butik/foderradgivning",
    url: "/sv/butik/foderradgivning",
    meta: food_45consultationonyIXKSevZMeta || {},
    alias: food_45consultationonyIXKSevZMeta?.alias || [],
    redirect: food_45consultationonyIXKSevZMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/food-consultation.vue").then(m => m.default || m)
  },
  {
    name: health_45checkpdU3eIDtRTMeta?.name ?? "shop-health-check___sv",
    path: health_45checkpdU3eIDtRTMeta?.path ?? "/sv/butik/health-check",
    url: "/sv/butik/health-check",
    meta: health_45checkpdU3eIDtRTMeta || {},
    alias: health_45checkpdU3eIDtRTMeta?.alias || [],
    redirect: health_45checkpdU3eIDtRTMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/health-check.vue").then(m => m.default || m)
  },
  {
    name: health_45checkpdU3eIDtRTMeta?.name ?? "shop-health-check___en",
    path: health_45checkpdU3eIDtRTMeta?.path ?? "/en/shop/health-check",
    url: "/en/shop/health-check",
    meta: health_45checkpdU3eIDtRTMeta || {},
    alias: health_45checkpdU3eIDtRTMeta?.alias || [],
    redirect: health_45checkpdU3eIDtRTMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/health-check.vue").then(m => m.default || m)
  },
  {
    name: indextobIe28DMUMeta?.name ?? "shop___sv",
    path: indextobIe28DMUMeta?.path ?? "/sv/butik",
    url: "/sv/butik",
    meta: indextobIe28DMUMeta || {},
    alias: indextobIe28DMUMeta?.alias || [],
    redirect: indextobIe28DMUMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: indextobIe28DMUMeta?.name ?? "shop___en",
    path: indextobIe28DMUMeta?.path ?? "/en/shop",
    url: "/en/shop",
    meta: indextobIe28DMUMeta || {},
    alias: indextobIe28DMUMeta?.alias || [],
    redirect: indextobIe28DMUMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93eiB3AJYl2eMeta?.name ?? "shop-info-slug___sv",
    path: _91_46_46_46slug_93eiB3AJYl2eMeta?.path ?? "/sv/butik/info/:slug",
    meta: _91_46_46_46slug_93eiB3AJYl2eMeta || {},
    alias: _91_46_46_46slug_93eiB3AJYl2eMeta?.alias || [],
    redirect: _91_46_46_46slug_93eiB3AJYl2eMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/info/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93eiB3AJYl2eMeta?.name ?? "shop-info-slug___en",
    path: _91_46_46_46slug_93eiB3AJYl2eMeta?.path ?? "/en/shop/info/:slug",
    meta: _91_46_46_46slug_93eiB3AJYl2eMeta || {},
    alias: _91_46_46_46slug_93eiB3AJYl2eMeta?.alias || [],
    redirect: _91_46_46_46slug_93eiB3AJYl2eMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/info/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexJEMBUpuFjLMeta?.name ?? "shop-info___sv",
    path: indexJEMBUpuFjLMeta?.path ?? "/sv/butik/info",
    url: "/sv/butik/info",
    meta: indexJEMBUpuFjLMeta || {},
    alias: indexJEMBUpuFjLMeta?.alias || [],
    redirect: indexJEMBUpuFjLMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/info/index.vue").then(m => m.default || m)
  },
  {
    name: indexJEMBUpuFjLMeta?.name ?? "shop-info___en",
    path: indexJEMBUpuFjLMeta?.path ?? "/en/shop/info",
    url: "/en/shop/info",
    meta: indexJEMBUpuFjLMeta || {},
    alias: indexJEMBUpuFjLMeta?.alias || [],
    redirect: indexJEMBUpuFjLMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/info/index.vue").then(m => m.default || m)
  },
  {
    name: loginRiyJwdfslsMeta?.name ?? "shop-login___sv",
    path: loginRiyJwdfslsMeta?.path ?? "/sv/butik/logga-in",
    url: "/sv/butik/logga-in",
    meta: loginRiyJwdfslsMeta || {},
    alias: loginRiyJwdfslsMeta?.alias || [],
    redirect: loginRiyJwdfslsMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/login.vue").then(m => m.default || m)
  },
  {
    name: order_45returnuDQiAvkmEeMeta?.name ?? "shop-order-return___sv",
    path: order_45returnuDQiAvkmEeMeta?.path ?? "/sv/butik/returer",
    url: "/sv/butik/returer",
    meta: order_45returnuDQiAvkmEeMeta || {},
    alias: order_45returnuDQiAvkmEeMeta?.alias || [],
    redirect: order_45returnuDQiAvkmEeMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/order-return.vue").then(m => m.default || m)
  },
  {
    name: order_45returnuDQiAvkmEeMeta?.name ?? "shop-order-return___en",
    path: order_45returnuDQiAvkmEeMeta?.path ?? "/en/shop/order-return",
    url: "/en/shop/order-return",
    meta: order_45returnuDQiAvkmEeMeta || {},
    alias: order_45returnuDQiAvkmEeMeta?.alias || [],
    redirect: order_45returnuDQiAvkmEeMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/order-return.vue").then(m => m.default || m)
  },
  {
    name: parasite_45testsnCsFaM7YlsMeta?.name ?? "shop-parasite-tests___sv",
    path: parasite_45testsnCsFaM7YlsMeta?.path ?? "/sv/butik/parasitundersokningar",
    url: "/sv/butik/parasitundersokningar",
    meta: parasite_45testsnCsFaM7YlsMeta || {},
    alias: parasite_45testsnCsFaM7YlsMeta?.alias || [],
    redirect: parasite_45testsnCsFaM7YlsMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/shop/parasite-tests.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93qetc8SIO1oMeta?.name ?? "shop-prescription-brand-slug___sv",
    path: _91_46_46_46slug_93qetc8SIO1oMeta?.path ?? "/sv/butik/produkt/:brand/:slug",
    meta: _91_46_46_46slug_93qetc8SIO1oMeta || {},
    alias: _91_46_46_46slug_93qetc8SIO1oMeta?.alias || [],
    redirect: _91_46_46_46slug_93qetc8SIO1oMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/prescription/[brand]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93h6whS9ooitMeta?.name ?? "shop-product-slug___sv",
    path: _91_46_46_46slug_93h6whS9ooitMeta?.path ?? "/sv/butik/produkt/:slug",
    meta: _91_46_46_46slug_93h6whS9ooitMeta || {},
    alias: _91_46_46_46slug_93h6whS9ooitMeta?.alias || [],
    redirect: _91_46_46_46slug_93h6whS9ooitMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93h6whS9ooitMeta?.name ?? "shop-product-slug___en",
    path: _91_46_46_46slug_93h6whS9ooitMeta?.path ?? "/en/shop/product/:slug",
    meta: _91_46_46_46slug_93h6whS9ooitMeta || {},
    alias: _91_46_46_46slug_93h6whS9ooitMeta?.alias || [],
    redirect: _91_46_46_46slug_93h6whS9ooitMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/product/[...slug].vue").then(m => m.default || m)
  },
  {
    name: receiptKOmvqH7SylMeta?.name ?? "shop-receipt___sv",
    path: receiptKOmvqH7SylMeta?.path ?? "/sv/butik/kvitto",
    url: "/sv/butik/kvitto",
    meta: receiptKOmvqH7SylMeta || {},
    alias: receiptKOmvqH7SylMeta?.alias || [],
    redirect: receiptKOmvqH7SylMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/receipt.vue").then(m => m.default || m)
  },
  {
    name: receiptKOmvqH7SylMeta?.name ?? "shop-receipt___en",
    path: receiptKOmvqH7SylMeta?.path ?? "/en/shop/receipt",
    url: "/en/shop/receipt",
    meta: receiptKOmvqH7SylMeta || {},
    alias: receiptKOmvqH7SylMeta?.alias || [],
    redirect: receiptKOmvqH7SylMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/receipt.vue").then(m => m.default || m)
  },
  {
    name: recommended_45productsl4SJFm3O2lMeta?.name ?? "shop-recommended-products___sv",
    path: recommended_45productsl4SJFm3O2lMeta?.path ?? "/sv/butik/rekommenderade-produkter",
    url: "/sv/butik/rekommenderade-produkter",
    meta: recommended_45productsl4SJFm3O2lMeta || {},
    alias: recommended_45productsl4SJFm3O2lMeta?.alias || [],
    redirect: recommended_45productsl4SJFm3O2lMeta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/recommended-products.vue").then(m => m.default || m)
  },
  {
    name: searchmBXBBJA9x8Meta?.name ?? "shop-search___sv",
    path: searchmBXBBJA9x8Meta?.path ?? "/sv/butik/sok",
    url: "/sv/butik/sok",
    meta: searchmBXBBJA9x8Meta || {},
    alias: searchmBXBBJA9x8Meta?.alias || [],
    redirect: searchmBXBBJA9x8Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/search.vue").then(m => m.default || m)
  },
  {
    name: searchmBXBBJA9x8Meta?.name ?? "shop-search___en",
    path: searchmBXBBJA9x8Meta?.path ?? "/en/shop/search",
    url: "/en/shop/search",
    meta: searchmBXBBJA9x8Meta || {},
    alias: searchmBXBBJA9x8Meta?.alias || [],
    redirect: searchmBXBBJA9x8Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/shop/search.vue").then(m => m.default || m)
  },
  {
    name: sitemapWOENLFgwrXMeta?.name ?? "sitemap___sv",
    path: sitemapWOENLFgwrXMeta?.path ?? "/sv/sitemap",
    url: "/sv/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    alias: sitemapWOENLFgwrXMeta?.alias || [],
    redirect: sitemapWOENLFgwrXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapWOENLFgwrXMeta?.name ?? "sitemap___uk",
    path: sitemapWOENLFgwrXMeta?.path ?? "/uk/sitemap",
    url: "/uk/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    alias: sitemapWOENLFgwrXMeta?.alias || [],
    redirect: sitemapWOENLFgwrXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapWOENLFgwrXMeta?.name ?? "sitemap___dk",
    path: sitemapWOENLFgwrXMeta?.path ?? "/dk/sitemap",
    url: "/dk/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    alias: sitemapWOENLFgwrXMeta?.alias || [],
    redirect: sitemapWOENLFgwrXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapWOENLFgwrXMeta?.name ?? "sitemap___de",
    path: sitemapWOENLFgwrXMeta?.path ?? "/de/sitemap",
    url: "/de/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    alias: sitemapWOENLFgwrXMeta?.alias || [],
    redirect: sitemapWOENLFgwrXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapWOENLFgwrXMeta?.name ?? "sitemap___us",
    path: sitemapWOENLFgwrXMeta?.path ?? "/us/sitemap",
    url: "/us/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    alias: sitemapWOENLFgwrXMeta?.alias || [],
    redirect: sitemapWOENLFgwrXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapWOENLFgwrXMeta?.name ?? "sitemap___fi",
    path: sitemapWOENLFgwrXMeta?.path ?? "/fi/sitemap",
    url: "/fi/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    alias: sitemapWOENLFgwrXMeta?.alias || [],
    redirect: sitemapWOENLFgwrXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: sitemapWOENLFgwrXMeta?.name ?? "sitemap___no",
    path: sitemapWOENLFgwrXMeta?.path ?? "/no/sitemap",
    url: "/no/sitemap",
    meta: sitemapWOENLFgwrXMeta || {},
    alias: sitemapWOENLFgwrXMeta?.alias || [],
    redirect: sitemapWOENLFgwrXMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: index5cfFJ2KABbMeta?.name ?? "symptom___dk",
    path: index5cfFJ2KABbMeta?.path ?? "/dk/symptomer",
    url: "/dk/symptomer",
    meta: index5cfFJ2KABbMeta || {},
    alias: index5cfFJ2KABbMeta?.alias || [],
    redirect: index5cfFJ2KABbMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: index5cfFJ2KABbMeta?.name ?? "symptom___de",
    path: index5cfFJ2KABbMeta?.path ?? "/de/symptome",
    url: "/de/symptome",
    meta: index5cfFJ2KABbMeta || {},
    alias: index5cfFJ2KABbMeta?.alias || [],
    redirect: index5cfFJ2KABbMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: index5cfFJ2KABbMeta?.name ?? "symptom___uk",
    path: index5cfFJ2KABbMeta?.path ?? "/uk/symptoms",
    url: "/uk/symptoms",
    meta: index5cfFJ2KABbMeta || {},
    alias: index5cfFJ2KABbMeta?.alias || [],
    redirect: index5cfFJ2KABbMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: index5cfFJ2KABbMeta?.name ?? "symptom___us",
    path: index5cfFJ2KABbMeta?.path ?? "/us/symptoms",
    url: "/us/symptoms",
    meta: index5cfFJ2KABbMeta || {},
    alias: index5cfFJ2KABbMeta?.alias || [],
    redirect: index5cfFJ2KABbMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: index5cfFJ2KABbMeta?.name ?? "symptom___fi",
    path: index5cfFJ2KABbMeta?.path ?? "/fi/oireet",
    url: "/fi/oireet",
    meta: index5cfFJ2KABbMeta || {},
    alias: index5cfFJ2KABbMeta?.alias || [],
    redirect: index5cfFJ2KABbMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: index5cfFJ2KABbMeta?.name ?? "symptom___no",
    path: index5cfFJ2KABbMeta?.path ?? "/no/symptomer",
    url: "/no/symptomer",
    meta: index5cfFJ2KABbMeta || {},
    alias: index5cfFJ2KABbMeta?.alias || [],
    redirect: index5cfFJ2KABbMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: index5cfFJ2KABbMeta?.name ?? "symptom___sv",
    path: index5cfFJ2KABbMeta?.path ?? "/sv/symtom",
    url: "/sv/symtom",
    meta: index5cfFJ2KABbMeta || {},
    alias: index5cfFJ2KABbMeta?.alias || [],
    redirect: index5cfFJ2KABbMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/symptom/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHv6tmKpEWMeta?.name ?? "terms-conditions___dk",
    path: indexsHv6tmKpEWMeta?.path ?? "/dk/brugervilkar",
    url: "/dk/brugervilkar",
    meta: indexsHv6tmKpEWMeta || {},
    alias: indexsHv6tmKpEWMeta?.alias || [],
    redirect: indexsHv6tmKpEWMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHv6tmKpEWMeta?.name ?? "terms-conditions___de",
    path: indexsHv6tmKpEWMeta?.path ?? "/de/nutzungsbedingungen",
    url: "/de/nutzungsbedingungen",
    meta: indexsHv6tmKpEWMeta || {},
    alias: indexsHv6tmKpEWMeta?.alias || [],
    redirect: indexsHv6tmKpEWMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHv6tmKpEWMeta?.name ?? "terms-conditions___uk",
    path: indexsHv6tmKpEWMeta?.path ?? "/uk/terms-of-service",
    url: "/uk/terms-of-service",
    meta: indexsHv6tmKpEWMeta || {},
    alias: indexsHv6tmKpEWMeta?.alias || [],
    redirect: indexsHv6tmKpEWMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHv6tmKpEWMeta?.name ?? "terms-conditions___us",
    path: indexsHv6tmKpEWMeta?.path ?? "/us/terms-and-conditions",
    url: "/us/terms-and-conditions",
    meta: indexsHv6tmKpEWMeta || {},
    alias: indexsHv6tmKpEWMeta?.alias || [],
    redirect: indexsHv6tmKpEWMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHv6tmKpEWMeta?.name ?? "terms-conditions___fi",
    path: indexsHv6tmKpEWMeta?.path ?? "/fi/kayttoehdot",
    url: "/fi/kayttoehdot",
    meta: indexsHv6tmKpEWMeta || {},
    alias: indexsHv6tmKpEWMeta?.alias || [],
    redirect: indexsHv6tmKpEWMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHv6tmKpEWMeta?.name ?? "terms-conditions___no",
    path: indexsHv6tmKpEWMeta?.path ?? "/no/vilkar-for-bruk",
    url: "/no/vilkar-for-bruk",
    meta: indexsHv6tmKpEWMeta || {},
    alias: indexsHv6tmKpEWMeta?.alias || [],
    redirect: indexsHv6tmKpEWMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: indexsHv6tmKpEWMeta?.name ?? "terms-conditions___sv",
    path: indexsHv6tmKpEWMeta?.path ?? "/sv/anvandarvillkor",
    url: "/sv/anvandarvillkor",
    meta: indexsHv6tmKpEWMeta || {},
    alias: indexsHv6tmKpEWMeta?.alias || [],
    redirect: indexsHv6tmKpEWMeta?.redirect || undefined,
    component: () => import("/home/node/client/.firstvet/pages/terms-conditions/index.vue").then(m => m.default || m)
  },
  {
    name: country_45selectRUZ8OV9dL5Meta?.name ?? "country-select",
    path: country_45selectRUZ8OV9dL5Meta?.path ?? "/",
    url: "/",
    meta: country_45selectRUZ8OV9dL5Meta || {},
    alias: country_45selectRUZ8OV9dL5Meta?.alias || [],
    redirect: country_45selectRUZ8OV9dL5Meta?.redirect || undefined,
    component: () => import("/home/node/client/pages/country-select.vue").then(m => m.default || m)
  }
]